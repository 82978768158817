import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Refresh extends Component {
  render() {
    return <Redirect to="/"/>
  }
}

export default Refresh;
