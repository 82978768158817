import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import FetchContent from '../components/home/FetchContent';
import SendResults from '../components/home/SendResults';
import NetworkStatus from '../components/home/NetworkStatus';
import TestURLs from '../components/home/TestURLs';

import HeaderText from '../components/communal/HeaderText';
import InteractionTimeout from '../components/communal/InteractionTimeout';
import SetTitle from '../components/communal/SetTitle';
import EmergencyButton from '../components/communal/EmergencyButton';
import ArrivingOrLeavingComponent from '../components/communal/ArrivingOrLeaving';
import ProfilesComponent from '../components/communal/Profiles';

import { timeFromLocalStorage } from '../utilities/generic/Common'

class Home extends Component {
  constructor(props) {
    super(props)

    this.updateHomePage = this.updateHomePage.bind(this)
    this.handleFetchSuccess = this.handleFetchSuccess.bind(this)
    this.handleFetchFailure = this.handleFetchFailure.bind(this)

    this.elementRefs = []
  }

  state = {
    key: 0,
    lastFetchSucceeded: null,
    lastFetchSuccessTime: timeFromLocalStorage("lastFetchSuccessTime")
  }

  updateHomePage() {
    this.setState({ key: Math.random()})
  }

  handleFetchFailure() {
    this.setState({ lastFetchSucceeded: false })
  }

  handleFetchSuccess() {
    const lastFetchSuccessTime = new Date()
    this.setState({
      lastFetchSucceeded: true,
      lastFetchSuccessTime: lastFetchSuccessTime
    })

    localStorage.setItem("lastFetchSuccessTime", lastFetchSuccessTime.toString())
  }

  updateCookieExpiry() {
    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined || token === "undefined") {
      return undefined
    }

    // Set Cookie again with an updated expiry.
    // We do this to hopefully skirt Safari's ITP restrictions.
    //
    // Can't expire after seven days if it's changing every 5 minutes!
    cookies.remove('device_token');
    cookies.set('device_token', token, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN, maxAge: 31557600 });

    return token
  }

  render() {
    const token = this.updateCookieExpiry()

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (localStorage.loaded !== "true") {
      return (
        <div>
          <FetchContent firstTime={true} updateHomePage={() => {}} handleFetchFailure={this.handleFetchFailure} handleFetchSuccess={this.handleFetchSuccess} />
        </div>
      )
    }

    let deviceName = JSON.parse(localStorage.getItem("device"))
    let location = JSON.parse(localStorage.getItem("location"))

    var device_help_message = location.device_help_message

    if (device_help_message === "") {
      device_help_message = "Please select the type of person you are, or scan the QR code to continue."
    }

    const flow_order = localStorage.flow_order || "profiles"

    return (
      <div>
        <EmergencyButton />
        <div className="main-page homepage">
          <InteractionTimeout seconds={300} />

          <SendResults handleFetchFailure={this.handleFetchFailure} handleFetchSuccess={this.handleFetchSuccess} />
          <FetchContent updateHomePage={this.updateHomePage} handleFetchFailure={this.handleFetchFailure} handleFetchSuccess={this.handleFetchSuccess} />

          <SetTitle title={"Welcome"} />
          <HeaderText size="big" />

          <div className="content">
            <div className="background">
              <div className="message">
                {device_help_message}
              </div>
            </div>
          </div>

          {flow_order && flow_order === "arriving_or_leaving" ? (
            <ArrivingOrLeavingComponent />
          ) : (
            <ProfilesComponent />
          )}
        </div>

        <div className="status-bar grid-two-columns">
          <NetworkStatus className="left status-textbox" lastFetchSucceeded={this.state.lastFetchSucceeded} lastFetchSuccessTime={this.state.lastFetchSuccessTime} />

          <div className="right status-textbox">
            {deviceName}
          </div>
        </div>

        <TestURLs />
      </div>
    );
  }
}

export default Home;
