import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import QRCode from "react-qr-code";
import Crypto from 'crypto';

class GenerateQRCode extends Component {
  encryptParams(profile) {
    let cookies = new Cookies()
    let token = cookies.get('device_token')
    let serverOffset = localStorage.getItem("server_offset")

    let physicalDeviceId = JSON.parse(localStorage.getItem("id"))
    let output = ""
    let message = ""

    // Catch and retry `"value" argument is out of bounds` error that `cipher.update` sometimes raises
    let retries = 0
    while(retries < 10) {
      try {
        // ISO 8601 string in UTC timezone
        let date = new Date().toISOString()

        if (profile === undefined) {
          // If the ArrivingOrLeaving page has been displayed first,
          // generate a QR code without `profile_id` or `type_of_person`.
          //
          // The Personal flow will add these in later, once a Profile has been selected.
          //
          message = `&&${date}&${serverOffset}&${physicalDeviceId}`
        }
        else {
          message = `${profile.id}&${profile.type_of_person}&${date}&${serverOffset}&${physicalDeviceId}`
        }

        // Encrypt params, using device_token
        let cipher = Crypto.createCipheriv("aes-256-cbc", token.substring(0, 32), "0000000000000000")
        output = cipher.update(message, 'utf-8', "base64")
        output += cipher.final("base64");

        // All is good if we made it this far!
        // Exit out of try/catch/retry
        retries = 10
      }
      catch(error) {
        if (error instanceof RangeError && retries < 9) {
          retries++;
        }
        else {
          // Unknown error.
          // Exit out of try/catch/retry
          retries = 10
          throw error
        }
      }
    }

    // Append device id so Rails can decrypt
    return `${output}/${physicalDeviceId}`;
  }

  showURL(url, profile) {
    // for development purposes only. can be switched on in .env file
    //
    if (process.env.REACT_APP_SHOW_QR_CODE_URL === "true") {
      return (
        <a href={url}>
          <br />
          {profile ? (
            `${profile.name} URL`
          ) : (
            "QR Code URL"
          )}
        </a>
      )
    }
  }

  photoRequired(profile) {
    if (profile === undefined) {
      return false
    }

    return (profile.arriving_photo_required && profile.arriving_photo_required !== "never") || (profile.leaving_photo_required && profile.leaving_photo_required !== "never")
  }

  render() {
    let profile = this.props.profile

    if (this.photoRequired(profile)) {
      const size = localStorage.getItem("qr_code_size") || "default"

      return (
        <div className={`qr-placeholder ${size}`}>
          QR CODE IS DISABLED
        </div>
      )
    }
    else {
      let url
      let params = encodeURIComponent(this.encryptParams(profile))

      if (profile === undefined) {
        url = `${process.env.REACT_APP_LOCAL_DOMAIN}/personal/new/${params}?page=home`
      }
      else {
        url = `${process.env.REACT_APP_LOCAL_DOMAIN}/personal/new/${params}`
      }

      const size = localStorage.getItem("qr_code_size") === "small" ? 128 : 256

      return (
        <div className="qr-code">
          <QRCode value={url} size={size} />

          { this.showURL(url, profile) }
        </div>
      )
    }
  }
}

export default GenerateQRCode;
