import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import HeaderText from '../../components/personal/HeaderText';
import SetTitle from '../../components/personal/SetTitle';

class ThankYou extends Component {

  message(profile) {
    var message = ""

    if (this.props.location.state.direction === "arriving") {
      message = profile.arrival_thank_you_message
    }
    else if (this.props.location.state.direction === "leaving") {
      message = profile.departure_thank_you_message
    }

    if (message === "") {
      message = "Thank you."
    }

    return message
  }

  render() {
    if (this.props.location.state === undefined) {
      return <Redirect to="/personal/404" />
    }

    let profile = this.props.location.state.profile;

    return (
      <div className="main-page">
        <SetTitle title={"Thank You"} profile={profile} />
        <HeaderText message={profile.welcome_message} />

        <div className="content">
          <div className="background">
            <div className="message">
              {this.message(profile)}
            </div>
          </div>
        </div>

        <div></div>
      </div>
    );
  }
}

export default ThankYou;
