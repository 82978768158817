import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'

import HeaderText from '../../components/communal/HeaderText';
import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';

import { formatDate, generateId, debounce, scrollElementToTop } from '../../utilities/generic/Common.js'

import { handleRadio, getAnonymousQuestionIds, answerForm, anonymousMessage } from '../../utilities/generic/Forms.js'

library.add(fas, far)

class Feedback extends Component {

  constructor(props) {
    super(props);

    this.debouncedScrollElementToTop = debounce(scrollElementToTop, 1000);
    this.handleChange = this.handleChange.bind(this);
    this.handleRadio = handleRadio.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    answers: {},
    initialTime: new Date(),

    validDetails: false,
    errors: "",
    error: ""
  }

  handleChange(event) {
    if ((new Date() - this.state.initialTime) < (parseInt(process.env.REACT_APP_INTERACTION_DELAY) || 0)) {
      return
    }

    let name = event.target.name;
    let value = event.target.value;
    let tag = event.target.tagName;

    if (this.state.answers[name] === value) {
      let answers = this.state.answers
      delete answers[name]

      this.setState({answers: answers})
    }
    else {
      this.setState(existingState => ({
        answers: {
          ...existingState.answers,
          [name]: value
        }
      }))
    }

    if (tag === "TEXTAREA") {
      this.debouncedScrollElementToTop(event.target)
    }
    else {
      scrollElementToTop(event.target)
    }
  }

  handleSubmit(event) {
    if (Object.keys(this.state.answers).length > 0) {
      // perform client-side validation here, display errors if needed

      let anonymousQuestionIds = getAnonymousQuestionIds(this)

      var answer = null

      var answersWithAppIds = {}

      for (const key in this.state.answers) {
        answersWithAppIds[generateId()] = {
          question_id: key,
          answer: this.state.answers[key]
        }
      }

      if (this.props.location.state.direction === "arriving") {
        answer = {
          arriving_app_id: this.props.location.state.directional_app_id,
          answers: answersWithAppIds,
          anonymous_question_ids: anonymousQuestionIds,
          app_id: generateId(),
          version: process.env.REACT_APP_COMMIT_HASH,
          created_at: formatDate(new Date())
        }
      }
      else {
        answer = {
          leaving_app_id: this.props.location.state.directional_app_id,
          answers: answersWithAppIds,
          anonymous_question_ids: anonymousQuestionIds,
          app_id: generateId(),
          version: process.env.REACT_APP_COMMIT_HASH,
          created_at: formatDate(new Date())
        }
      }

      var answers = JSON.parse(localStorage.getItem("answers"))

      // Create answers array if it doesn't exist
      if (answers === null) {
        answers = []
      }

      // Append to answers array, and save to semi-permanent storage
      answers.push(answer)
      localStorage.setItem("answers", JSON.stringify(answers))
    }

    this.setState({validDetails: true})

    event.preventDefault();
  }

  render() {
    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    let profile = this.props.location.state.profile
    let direction = this.props.location.state.direction

    if (this.state.validDetails) {
      return <DestinationSwitch current_page="feedback" profile={profile} direction={direction} />
    }


    let feedbackTypes = this.props.location.state.feedbackTypes

    return (
      <div className="main-page feedback-page">
        <InteractionTimeout seconds={60} />

        <SetTitle title={"Feedback"} />
        <HeaderText />

        <div className="content">

          <div className="feedback">
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              {feedbackTypes.map((feedback) => {
                if (feedback.feedback_questions.length !== 0) {
                  return (
                    <div className="feedback-type" key={feedback.id}>
                      { anonymousMessage(feedback.anonymous) }

                      <div className="feedback-title">{feedback.display}</div>

                      {feedback.feedback_questions.map((question) => {
                        return answerForm(question, this)
                      })}
                    </div>
                  )
                }
                else {
                  return(null)
                }
              })}

              <div className="submit-container">
                <input type="submit" value="Submit" />
              </div>
            </form>
            
          </div>

        </div>
      </div>
    );
  }
}

export default Feedback;
