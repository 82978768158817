import React from 'react';

import { faLaughBeam, faSmile, faFrown, faTired, faMeh } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function handleChange(event) {
  let name = event.target.name;
  let value = event.target.value;
  this.setState({[name]: value});
}

//arriving/leaving

export function requiredString(key, otherOptions = "") {
  if (key === true) {
    return "required|" + otherOptions;
  }
  else {
    return otherOptions
  }
}

export function renderRequired(key) {
  if (key === true) {
    return (
      <span className="red">*</span>
    )
  }
}

export function directionHandler(_this) {
  if (_this.state.direction === "leaving") {
    return "departure"
  }
  else {
    return "arrival"
  }
}

export function directionPluralHandler(_this) {
  if (_this.state.direction === "leaving") {
    return "departures"
  }
  else {
    return "arrivals"
  }
}

export function directionCheckHandler(_this) {
  if (_this.state.direction === "leaving") {
    return "check_out_time"
  }
  else {
    return "check_in_time"
  }
}

//feedback

export function handleRadio(event) {
  let name = event.target.name;
  let value = parseInt(event.target.value);

  this.setState({[name]: value});
}

export function getFeedbackForDirection(_this) {
  let profile = _this.props.location.state.profile
  let direction = _this.props.location.state.direction

  let showOn = direction === "arriving" ? "Arrival" : "Departure"

  var feedbackTypes = []
  profile.feedback_types.forEach((feedback) => {
    if (feedback.show_on === showOn) {
      feedbackTypes.push(feedback)
    }
  })

  return feedbackTypes
}

export function getAnonymousQuestionIds(_this) {
  let feedbackTypes = getFeedbackForDirection(_this)
  let anonymousFeedback = feedbackTypes.filter((feedback) => feedback.anonymous === true)

  var anonymousQuestionIds = []
  anonymousFeedback.forEach((feedback) => {
    feedback.feedback_questions.forEach((question) => {
      anonymousQuestionIds.push(question.id)
    })
  })

  return anonymousQuestionIds
}

export function answerForm(question, _this) {
  if (question.answer_type === "boolean") {
    return (
      <div className="question radio" key={question.id}>
        <div className="question-title">{question.display}</div>
        <input className="column" type="checkbox" name={question.id} value="yes" id={`yes_${question.id}`} checked={_this.state.answers[question.id] === "yes"} onChange={_this.handleChange} />
        <label className="longer" htmlFor={`yes_${question.id}`}>Yes</label>
        <input className="column" type="checkbox" name={question.id} value="no" id={`no_${question.id}`} checked={_this.state.answers[question.id] === "no"} onChange={_this.handleChange} />
        <label className="longer" htmlFor={`no_${question.id}`}>No</label>
      </div>
    )
  }

  if (question.answer_type === "stars") {
    let numberOfStars = _this.state.answers[question.id]
    var iconType = []

    for (var i = 0; i < numberOfStars; i++) {
      iconType.push("fas")
    }

    return (
      <div className="question radio" key={question.id}>
        <div className="question-title">{question.display}</div>

        <div className="mobile-stars">
          <input className="column" type="checkbox" name={question.id} value="1" id={`one_star_${question.id}_mobile`} checked={_this.state.answers[question.id] === "1"} onChange={_this.handleChange} />
          <label htmlFor={`one_star_${question.id}_mobile`}>
            <FontAwesomeIcon icon={[iconType[0] || "far", "star"]} style={{color: iconType[0] ? "gold" : "#777"}} />
          </label>
          <input className="column" type="checkbox" name={question.id} value="2" id={`two_stars_${question.id}_mobile`} checked={_this.state.answers[question.id] === "2"} onChange={_this.handleChange} />
          <label htmlFor={`two_stars_${question.id}_mobile`}>
            <FontAwesomeIcon icon={[iconType[1] || "far", "star"]} style={{color: iconType[1] ? "gold" : "#777"}} />
          </label>
          { question.answer_quantity !== 4 &&
            <>
              <input className="column" type="checkbox" name={question.id} value="3" id={`three_stars_${question.id}_mobile`} checked={_this.state.answers[question.id] === "3"} onChange={_this.handleChange} />
              <label htmlFor={`three_stars_${question.id}_mobile`}>
                <FontAwesomeIcon icon={[iconType[2] || "far", "star"]} style={{color: iconType[2] ? "gold" : "#777"}} />
              </label>
            </>
          }
          <input className="column" type="checkbox" name={question.id} value="4" id={`four_stars_${question.id}_mobile`} checked={_this.state.answers[question.id] === "4"} onChange={_this.handleChange} />
          <label htmlFor={`four_stars_${question.id}_mobile`}>
            <FontAwesomeIcon icon={[iconType[3] || "far", "star"]} style={{color: iconType[3] ? "gold" : "#777"}} />
          </label>
          <input className="column" type="checkbox" name={question.id} value="5" id={`five_stars_${question.id}_mobile`} checked={_this.state.answers[question.id] === "5"} onChange={_this.handleChange} />
          <label htmlFor={`five_stars_${question.id}_mobile`}>
            <FontAwesomeIcon icon={[iconType[4] || "far", "star"]} style={{color: iconType[4] ? "gold" : "#777"}} />
          </label>
        </div>
      </div>
    )
  }

  if (question.answer_type === "text") {
    return (
      <div className="question text form" key={question.id}>
        <div className="question-title">{question.display}</div>
        <textarea aria-required="true" name={question.id} value={_this.state.answers[question.id]} onChange={_this.handleChange} />
      </div>
    )
  }

  if (question.answer_type === "smileys") {
    return (
      <div className="question radio" key={question.id}>
        <div className="question-title">{question.display}</div>

        <div className="smileys">
          <span className="very_unhappy">
            <input className="column" type="checkbox" name={question.id} value="1" id={`very_unhappy_${question.id}`} checked={_this.state.answers[question.id] === "1"} onChange={_this.handleChange} />
            <label htmlFor={`very_unhappy_${question.id}`}>
              <FontAwesomeIcon icon={faTired} />
            </label>
          </span>
          <span className="unhappy">
            <input className="column" type="checkbox" name={question.id} value="2" id={`unhappy_${question.id}`} checked={_this.state.answers[question.id] === "2"} onChange={_this.handleChange} />
            <label htmlFor={`unhappy_${question.id}`}>
              <FontAwesomeIcon icon={faFrown} />
            </label>
          </span>
          { question.answer_quantity === 5 &&
            <span className="yellow">
              <input className="column" type="checkbox" name={question.id} value="3" id={`neutral_${question.id}`} checked={_this.state.answers[question.id] === "3"} onChange={_this.handleChange} />
              <label htmlFor={`neutral_${question.id}`}>
                <FontAwesomeIcon icon={faMeh} />
              </label>
            </span>
          }
          <span className={question.answer_quantity === 5 ? "light-green" : "yellow"}>
            <input className="column" type="checkbox" name={question.id} value="4" id={`happy_${question.id}`} checked={_this.state.answers[question.id] === "4"} onChange={_this.handleChange} />
            <label htmlFor={`happy_${question.id}`}>
              <FontAwesomeIcon icon={faSmile} />
            </label>
          </span>
          <span className="very_happy">
            <input className="column" type="checkbox" name={question.id} value="5" id={`very_happy_${question.id}`} checked={_this.state.answers[question.id] === "5"} onChange={_this.handleChange} />
            <label htmlFor={`very_happy_${question.id}`}>
              <FontAwesomeIcon icon={faLaughBeam} />
            </label>
          </span>
        </div>
      </div>
    )
  }
}

export function anonymousMessage(anonymous) {
  if (anonymous === true) {
    return (
      <div className="anonymous-text">
        This feedback is anonymous. It will not be saved against your name.
      </div>
    )
  }
}

//vistingwho

export function handleMultiCheckbox(event) {
  let name = parseInt(event.target.name);
  let value = event.target.checked;

  var people_ids = this.state.people_ids

  if (value === true) {
    if (!people_ids.includes(name)) {
      people_ids.push(name)
    }
  }
  else if (value === false) {
    if (people_ids.includes(name)) {
      let index = people_ids.indexOf(name)
      people_ids.splice(index, 1)
    }
  }

  this.setState({
    people_ids: people_ids,
  });
}

export function skipOrSelectText(people_ids, typed_names) {
  return people_ids.length === 0 && typed_names.join("") === "" ? "Not visiting anyone" : "Submit"
}

export function selectPersonText(relationships) {
  let quantity = relationships.length === 1 ? "person" : "people"

  return `Select which ${quantity} you're visiting`
}

//information
export function handleCheckbox(event) {
  let name = event.target.name;
  let value = event.target.checked;

  this.setState({[name]: value});
}

export function handleValidationSubmit(event) {
  if (this.validator.allValid()) {
    this.setState({validDetails: true})
  }
  else {
    this.validator.showMessages();
    this.forceUpdate();
  }

  event.preventDefault();
}

export function safeStringComparison(record, incoming) {
  // First argument needs to be localStorage record so that comparisons can be ignored when that value is null.
  if (typeof record === "string" && typeof incoming === "string") {
    if (record === "") {
      return true
    }
    return (record.trim().toUpperCase() === incoming.trim().toUpperCase())
  }
  else if (record === null) {
    return true
  }
  else return false
}

export function safeSubstringFuzzyComparison(record, value) {
  // First argument needs to be localStorage record so that comparisons can be ignored when that value is null.
  if (typeof record === "string" && typeof value === "string") {
    if (record === "" || value === "") {
      return true
    }
    return (record.trim().toUpperCase().substr(0, value.trim().length) === value.trim().toUpperCase())
  }
  else if (record === null) {
    return true
  }
  else return false
}

export function splitAlternativeNameSubstringComparison(alternate_names, value) {
  if (alternate_names === undefined || alternate_names === null || alternate_names === "") {
    return false
  }

  let matchFound = false

  alternate_names.split(",").forEach((record) => {
    if (safeSubstringFuzzyComparison(record, value) === true) {
      matchFound = true
      return
    }
  })

  return matchFound
}


export function safeSubstringActiveComparison(record, value) {
  // Ignore empty input fields
  if (value.trim().length === 0) {
    return true
  }
    return ((record && record.trim().toUpperCase().substr(0, value.trim().length)) === value.trim().toUpperCase())
}
