import React, { Component } from 'react';

class SetTitle extends Component {
  state = {
    currentTitle: "",
    previousTitle: ""
  }

  componentDidMount() {
    let location = JSON.parse(localStorage.getItem("location"))
    let locationName = location === null ? "" : `| ${location.name}`

    let title = this.props.title;

    let currentTitle = `${title} ${locationName} | Digital Reception`

    this.setState({"previousTitle": document.title})
    this.setState({"currentTitle": currentTitle})

    document.title = currentTitle
  }

  componentWillUnmount() {
    document.title = this.state.previousTitle
  }

  render() {
    return (
      <>
      </>
    );
  }
};

export default SetTitle;
