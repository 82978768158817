import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ArrivingOrLeavingComponent extends Component {
  render() {
    const token = this.props.token
    const profile = this.props.profile
    const profiles = this.props.profiles
    const location = this.props.location

    if (profile === undefined) {
      // Profile hasn't been selected, so the Device has been configured to show Arriving or Leaving option first
      return (
        <div className="widgets" style={{gridTemplateColumns: "auto auto"}}>
          <Link to={{ pathname: '/personal/profiles', state: { direction: "arriving", token: token, profiles: profiles, location: location } }} className="widget">
            Arriving
          </Link>

          <Link to={{ pathname: '/personal/profiles', state: { direction: "leaving", token: token, profiles: profiles, location: location } }} className="widget">
            Leaving
          </Link>
        </div>
      )
    }
    else {
      return (
        <div className="widgets"  style={{gridTemplateColumns: "auto auto"}}>
          <Link to={{ pathname: '/personal/arriving', state: { profile: profile, token: token, previous_page: "arriving_or_leaving" } }} className="widget">
            Arriving
          </Link>

          <Link to={{ pathname: '/personal/leaving', state: { profile: profile, token: token, previous_page: "arriving_or_leaving" } }} className="widget">
            Leaving
          </Link>
        </div>
      )
    }
  }
}

export default ArrivingOrLeavingComponent;
