import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import HeaderText from '../../components/personal/HeaderText';
import SetTitle from '../../components/personal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';

import { handleCheckbox, handleValidationSubmit } from '../../utilities/generic/Forms.js'

class Information extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({
      messages: {
        accepted: 'Confirmation is required.'
      },
    });

    this.handleCheckbox = handleCheckbox.bind(this);
    this.handleValidationSubmit = handleValidationSubmit.bind(this);
  }

  state = {
    checkbox: false,
    validDetails: false
  };

  render() {
    if (this.props.location.state === undefined) {
      return <Redirect to="/personal/404" />
    }

    let profile = this.props.location.state.profile;
    let direction = this.props.location.state.direction

    var information = ""
    var confirmation = ""

    if (direction === "arriving") {
      information = profile.information_on_check_in
      confirmation = profile.check_in_info_confirmation_needed
    }
    else {
      information = profile.information_on_check_out
      confirmation = profile.check_out_info_confirmation_needed
    }

    if (this.state.validDetails) {
      return <DestinationSwitch current_page="information" profile={profile} direction={direction} personal={true} />
    }

    if (confirmation === false) {
      return (
        <Link to={{ pathname: "/personal/thank-you", state: { profile: profile, direction: direction } }} className="main-page">
          <SetTitle title={"Information"} profile={profile} />
          <HeaderText message={profile.welcome_message} />

          <div className="content">
            <div className="confirm-form">
              <div className="message">
                {information}
              </div>

              <form>
                <input type="submit" value="Continue" />
              </form>
            </div>
          </div>

          <div></div>
        </Link>
      );
    }

    const { checkbox } = this.state;

    if (confirmation === true) {
      return (
        <div className="main-page">
          <SetTitle title={"Information"} profile={profile} />
          <HeaderText message={profile.welcome_message} />

          <div className="content">

            <div className="confirm-form">
              <div className="message">
                {information}
              </div>

              <form onSubmit={this.handleValidationSubmit}>
                <input className="column" type="checkbox" name="checkbox" id="checkbox" checked={checkbox} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="checkbox">Tick the box to confirm you have read this message</label>
                {this.validator.message('confirmation', this.state.checkbox, 'accepted', { className: 'error bottom-margin' })}

                <div>
                  <input type="submit" value="Confirm" />
                </div>
              </form>
            </div>
          </div>

          <div></div>
        </div>
      );
    }
  }
}

export default Information;
