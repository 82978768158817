import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import InteractionTimeout from '../components/communal/InteractionTimeout';
import SetTitle from '../components/communal/SetTitle';

class Error404 extends Component {
  render() {
    return (
      <div>
        <InteractionTimeout seconds={10} />
        <SetTitle title={"Page Not Found"} />

        <div className="error-page">
          <h2>Page not found</h2>

          <div>Sorry, that page doesn't seem to exist. Try going back to the <Link to={'/'}>homepage</Link>.</div>
        </div>
      </div>
    );
  }
}

export default Error404;
