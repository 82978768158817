import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import '../../shims/big-int'
import QrReader from 'react-qr-reader';

import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'

import HeaderText from '../../components/communal/HeaderText';
import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';

import { formatDate, generateId } from '../../utilities/generic/Common.js'
import { handleCovidPassExpiryDate } from '../../utilities/generic/CovidPass.js';

library.add(fas, far)

class CovidQR extends Component {

  constructor(props) {
    super(props);

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    answers: {},
    result: "Please Scan your Covid vaccination QR code",
    validDetails: false,
    errors: "",
    error: ""
  }

  handleScan = data => {

    try {
      if (data) {
        try {
          var expiryDate = handleCovidPassExpiryDate(data)
        }
        catch(err) {
          this.setState ({
            result: "Something went wrong. Please scan again or try another QR code if possible"
          })
        }

        if (expiryDate instanceof Date) {
          try {
            let currentDate = new Date().setHours(0,0,0,0)
            if (expiryDate > currentDate) {
              let covidResult = {
                "covid_pass_expiry_date": formatDate(expiryDate),
                "arriving_app_id": this.props.location.state.arriving_app_id,
                "app_id": generateId(),
                "profile_id": this.props.location.state.profile.id,
                "version": process.env.REACT_APP_COMMIT_HASH,
                "created_at": formatDate(new Date())
              }
              let covidResults = JSON.parse(localStorage.getItem("covid_results"))

              // Create covid_results array if it doesn't exist
              if (covidResults === null) {
                covidResults = []
              }

              // Append to covid_results array, and save to semi-permanent storage
              covidResults.push(covidResult)
              localStorage.setItem("covid_results", JSON.stringify(covidResults))

              // Append to localStorage so even offline devices can potentially covid QR scanning frequency
              const person = this.props.location.state.person

              if (person.id !== undefined) {
                let people = JSON.parse(localStorage.getItem("people"))
                people.forEach((potentialPerson) => {
                  if (person.id === potentialPerson.id) {
                    potentialPerson.covid_pass_expiry_date = formatDate(expiryDate);
                    return
                  }
                })
                localStorage.setItem("people", JSON.stringify(people))
              }

              this.setState ({
                result: "COVID-19 Vaccination Pass Check Complete!",
                validDetails: true
              })
              // do we also want to tell visitors when their pass will expire, either if it's close to expiring or every time?
            }

            else {
              this.setState ({
                result: "Please renew your COVID-19 Vaccination Pass before continuing!"
              })
            }
          }
          catch(err) {
          }
        }
        else {
          this.setState ({
            result: "Please Scan a valid Covid Vaccination Pass"
          })
        }
      }
    }
    catch(err) {
      this.setState ({
        result: "Please Scan your Covid vaccination QR code"
      })
    }
  }

  handleError = err => {
    this.setState ({
      result: "There was an error scanning your Covid vaccination QR code. Please try again"
    })
  }

  handleSubmit(event) {

    let covidResult = {
      "covid_pass_expiry_date": null,
      "arriving_app_id": this.props.location.state.arriving_app_id,
      "app_id": generateId(),
      "profile_id": this.props.location.state.profile.id,
      "version": process.env.REACT_APP_COMMIT_HASH,
      "created_at": formatDate(new Date())
    }
    let covidResults = JSON.parse(localStorage.getItem("covid_results"))

    // Create covid_results array if it doesn't exist
    if (covidResults === null) {
      covidResults = []
    }

    // Append to covid_results array, and save to semi-permanent storage
    covidResults.push(covidResult)
    localStorage.setItem("covid_results", JSON.stringify(covidResults))

    this.setState ({
      result: "COVID-19 Vaccination Pass Check Skipped.",
      validDetails: true
    })
  }

  render() {

    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    let profile = this.props.location.state.profile
    let person = this.props.location.state.person
    let arriving_app_id = this.props.location.state.arriving_app_id
    let direction = this.props.location.state.direction
    let planned_event_ids = this.props.location.state.planned_event_ids

    if (this.state.validDetails) {
      return <DestinationSwitch current_page="covid-qr" profile={profile} person={person} arriving_app_id={arriving_app_id} direction={direction} planned_event_ids={planned_event_ids} />
    }

    try {
      return (
        <div className="main-page covid-qr-page">
          <InteractionTimeout seconds={120} />

          <div className="camera-container">
            <div className="background">
              {this.state.result}
            </div>
            <br/>
            <QrReader
              facingMode={"user"}
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '55vh', margin: 'auto'}}
            />
            <div className="form">
              <input type="submit" onClick={this.handleSubmit} value="Skip if unable to provide." />
            </div>
          </div>

          <SetTitle title={"Covid QR Scan"} />
          <HeaderText />
        </div>
      );
    }
    catch(err) {
    }
  }
}

export default CovidQR;
