import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProfilesComponent extends Component {
  gridColumns(count) {
    var output = ""

    for (var i = 0; i < count; i++) {
      output += "auto "
    }

    return output
  }

  render() {
    const profiles = this.props.profiles
    const direction = this.props.direction
    const token = this.props.token

    if (direction === undefined) {
      // Direction hasn't been selected, so the Device has been configured to show Profiles option first
      return (
        <div className="widgets" style={{gridTemplateColumns: this.gridColumns(profiles.length)}}>
          {profiles.map((profile) => (
            <div key={profile.id} className="fit-content-center">
              <Link to={{ pathname: '/personal/arriving-or-leaving', state: { profile_id: profile.id, token: token } }} className="widget">
                {profile.name}
              </Link>
            </div>
          ))}
        </div>
      )
    }
    else {
      return (
        <div className="widgets vertical" style={{gridTemplateColumns: this.gridColumns(profiles.length)}}>
          {profiles.map((profile) => (
            <div key={profile.id} className="fit-content-center">
              <button onClick={this.props.pullAndRedirect} value={profile.id} data-type-of-person={profile.type_of_person} className="widget">
                {profile.name}
              </button>
            </div>
          ))}
        </div>
      )
    }
  }
}

export default ProfilesComponent;
