import React, { Component } from 'react';

import Logo from '../shared/Logo';

class HeaderText extends Component {
  render() {

    var welcomeMessage = this.props.message;

    if (welcomeMessage === "") {
      welcomeMessage = "Welcome to Digital Reception"
    }

    let logo = null

    if (localStorage !== null) {
      logo = localStorage.getItem("logo")
    }

    const className = (logo === null || logo === "null") ? "header" : "header with-logo"

    return (
      <div className={className}>
        <Logo />
        {welcomeMessage}
      </div>
    )
  }
}

export default HeaderText;
