import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TestURLs extends Component {
  render() {

    // load these hidden links, but only when we're running in test mode.
    // these will match up with the tokens we've mocked in src/setupTests.js
    if (process.env.NODE_ENV === "test") {
      return (
        <div className="personal-test-urls">
          <Link to={`/personal/new/arriving-with-feedback-and-information-confirmation-needed`}>Arriving with Feedback and Information, Confirmation Needed</Link>
          <Link to={`/personal/new/arriving-with-feedback-and-information-confirmation-not-needed`}>Arriving with Feedback and Information, Confirmation Not Needed</Link>
          <Link to={`/personal/new/arriving-with-feedback-but-without-information`}>Arriving with Feedback but without Information</Link>
          <Link to={`/personal/new/arriving-without-feedback-but-with-information-confirmation-needed`}>Arriving without Feedback but with Information, Confirmation Needed</Link>
          <Link to={`/personal/new/arriving-without-feedback-but-with-information-confirmation-not-needed`}>Arriving without Feedback but with Information, Confirmation Not Needed</Link>
          <Link to={`/personal/new/arriving-without-feedback-or-information`}>Arriving without Feedback or Information</Link>

          <Link to={`/personal/new/leaving-with-feedback-and-information-confirmation-needed`}>Leaving with Feedback and Information, Confirmation Needed</Link>
          <Link to={`/personal/new/leaving-with-feedback-and-information-confirmation-not-needed`}>Leaving with Feedback and Information, Confirmation Not Needed</Link>
          <Link to={`/personal/new/leaving-with-feedback-but-without-information`}>Leaving with Feedback but without Information</Link>
          <Link to={`/personal/new/leaving-without-feedback-but-with-information-confirmation-needed`}>Leaving without Feedback but with Information, Confirmation Needed</Link>
          <Link to={`/personal/new/leaving-without-feedback-but-with-information-confirmation-not-needed`}>Leaving without Feedback but with Information, Confirmation Not Needed</Link>
          <Link to={`/personal/new/leaving-without-feedback-or-information`}>Leaving without Feedback or Information</Link>
        </div>
      )
    }

    // default to returning nothing
    return (
      <>
      </>
    )

  }
}

export default TestURLs;
