import React, { Component } from 'react';

import { timeFromLocalStorage } from '../../utilities/generic/Common'

class NetworkStatus extends Component {
  constructor(props) {
    super(props)

    this.resetTimeContext = this.resetTimeContext.bind(this)
    this.handleOffWifi = this.handleOffWifi.bind(this)
    this.handleOnWifi = this.handleOnWifi.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  state = {
    onWifi: navigator.onLine,
    lastOnWifi: timeFromLocalStorage("lastOnWifi"),
    timeContextIDs: [],
    showModal: false
  }

  openModal() {
    this.setState({ showModal: true })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  resetTimeContext() {
    let timeContextID = setTimeout(this.resetTimeContext, 60000)
    this.setState((prevState) => ({
      timeContextIDs: [...prevState.timeContextIDs.slice(-4), timeContextID]
    }))
  }

  handleOffWifi() {
    const lastOnWifi = new Date()
    this.setState({
      onWifi: false,
      lastOnWifi: lastOnWifi
    })

    localStorage.setItem("lastOnWifi", lastOnWifi.toString())
  }

  handleOnWifi() {
    this.setState({ onWifi: true})
  }

  timeComparisonString(olderTime, newerTime = new Date()) {
    const elapsedTime = new Date(newerTime - olderTime);

    const months = elapsedTime.getUTCMonth();
    const days = elapsedTime.getUTCDate() - 1; // -1 as dates start at 1
    const hours = elapsedTime.getUTCHours();
    const minutes = elapsedTime.getUTCMinutes();
    let timeString = "";

    if (months) {
      timeString += `${months} month`

      if (months > 1) {
        timeString += "s"
      }
    }
    else if (days) {
      timeString += `${days} day`

      if (days > 1) {
        timeString += "s"
      }
    }
    else if (hours) {
      timeString += `${hours} hour`

      if (hours > 1) {
        timeString += "s"
      }
    }
    else if (minutes) {
      timeString += `${minutes} minute`

      if (minutes > 1) {
        timeString += "s"
      }
    }

    return timeString
  }

  renderModal(circleColour) {
    if (this.state.showModal) {
      let message = "";
      let feedback = []

      if (circleColour === "green") {
        message = "This device is running in Online mode"
      }
      if (circleColour === "yellow") {
        message = "This device is trying to connect"
      }
      if (circleColour === "red") {
        message = "This device is running in Offline mode"
        feedback.push("You can still arrive and depart as normal, but data won't be synced until the device comes back online.")
      }


      if (this.props.lastFetchSuccessTime) {
        let lastFetchSuccessTimeString = this.timeComparisonString(this.props.lastFetchSuccessTime) || "less than a minute"
        feedback.push(`The latest data sync was ${lastFetchSuccessTimeString} ago`)
      }
      else {
        feedback.push("The time of the latest data sync could not be retreived.")
      }

      feedback.push("Tap anywhere to dismiss this message")
      return (
        <div onClick={this.closeModal} className="modal-container">
          <div className="modal-content">
            <label className="column">{message}</label>
            {feedback.map((item, index) => (
              <ul key={index}>
                <li>{item}</li>
              </ul>
            ))}
            <div>
              <button className="modal-button" onClick={this.closeModal}>Close</button>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    let message, circleColour, urgency;

    if (this.state.onWifi && this.props.lastFetchSucceeded === null) {
      circleColour = "yellow";
      message = "Calculating Online Status..."
    }
    else if (this.state.onWifi && this.props.lastFetchSucceeded === true) {
      circleColour = "green"
      message = "Online"
    }
    else {
      message = ""
      circleColour = "red"

      if (this.state.onWifi === false) {
        message += "No WiFi"

        if (this.state.lastOnWifi) {
          const timeString = this.timeComparisonString(this.state.lastOnWifi);
          if (timeString) {
            message += `: Last on Wifi ${timeString} ago.`
          }
        }
      }
      else if (this.props.lastFetchSucceeded === false) {
        message += "No internet"
        if (this.props.lastFetchSuccessTime) {
          const timeString = this.timeComparisonString(this.props.lastFetchSuccessTime);
          if (timeString){
            message += `: Last connected ${timeString} ago.`
          }
        }
      }

      const timeOffline = new Date (new Date() - (this.props.lastFetchSuccessTime || this.state.lastOnWifi))

      if (timeOffline.getUTCMonth() > 0 || timeOffline.getUTCDate() > 1) {
        urgency = "maximum"
      }
      else if (timeOffline.getUTCHours() >= 6) {
        urgency = "high"
      }
      else if (timeOffline.getUTCHours() >= 1) {
        urgency = "low"
      }
      else {
        urgency = "minimal"
      }

      urgency += "-urgency"
    }

    return (
      <>
        <div onClick={this.openModal} className={this.props.className}>
          <div className={urgency}><div className={`${circleColour} circle`}/>{message}</div>
        </div>
        {this.renderModal(circleColour)}
      </>
    )
  }

  componentDidMount() {
    window.addEventListener("offline", this.handleOffWifi)
    window.addEventListener("online", this.handleOnWifi)
    this.resetTimeContext()
  }

  componentWillUnmount() {
    window.removeEventListener("offline", this.handleOffWifi)
    window.removeEventListener("online", this.handleOnWifi)

    this.state.timeContextIDs.forEach((timeContextID) => {
      clearTimeout(timeContextID)
    })
  }
}

export default NetworkStatus;
