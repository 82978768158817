import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { formatDate } from '../../utilities/generic/Common.js'

class SendResults extends Component {

  state = {
    error: null,
    unauthorized: null
  };

  render() {
    const { unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/setup/user"/>
    }

    // This component is just here to push necessary data.
    // We don't need to return anything to the page
    return (
      <>
      </>
    );
  }

  removeSuccessfulEvents(data, eventType) {
    // ignore this eventType if there is no data to process
    if (data.length === 0) {
      return
    }

    let events = JSON.parse(localStorage.getItem(eventType)) || []
    let failedEvents = JSON.parse(localStorage.getItem("failed_" + eventType)) || []

    // Skip this eventType if no data found in localStorage
    if (events === [] && failedEvents === []) {
      return
    }

    let successfulResponses = data.filter((item) => item[Object.keys(item)[0]] === true).map((item) => Object.keys(item)[0])
    let failedResponses = data.filter((item) => item[Object.keys(item)[0]] === false).map((item) => Object.keys(item)[0])


    if (failedResponses.length > 0) {
      // create a new array of just events that came back as failed
      let filteredNegativeEvents = events.filter((event) => failedResponses.includes(event.app_id))

      // add them to existing failed events store
      failedEvents = failedEvents.concat(filteredNegativeEvents)

      // remove any duplicated entries
      failedEvents = failedEvents.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.app_id === value.app_id
        ))
      )
    }

    // filter out processed answers as well as any formerly failed answers that have come back as successful
    events = events.filter((event) => !successfulResponses.includes(event.app_id) && !failedResponses.includes(event.app_id))
    failedEvents = failedEvents.filter((event) => !(successfulResponses.includes(event.app_id)))

    localStorage.setItem(eventType, JSON.stringify(events))
    localStorage.setItem("failed_" + eventType, JSON.stringify(failedEvents))

  }

  removeSuccessfulAnswers(data) {
    if (data.length === 0) {
      return
    }

    let events = JSON.parse(localStorage.getItem("answers")) || []
    let failedEvents = JSON.parse(localStorage.getItem("failed_answers")) || []

    // Skip this eventType if no data found in localStorage
    if (events === [] && failedEvents === []) {
      return
    }
    // collect keys of all responses that contain only successes and those that also contain failures
    let successfulResponses = data.filter((responseBlock) => !this.responseBlockContainsFalse(responseBlock)).map((item) => Object.keys(item)[0])
    let failedResponses = data.filter((responseBlock) => this.responseBlockContainsFalse(responseBlock)).map((item) => Object.keys(item)[0])


    if (failedResponses.length > 0) {
      // create a new array of just events that came back as failed
      let filteredNegativeEvents = events.filter((event) => failedResponses.includes(event.app_id))

      // add them to existing failed events store
      failedEvents = failedEvents.concat(filteredNegativeEvents)

      // remove any duplicated entries
      failedEvents = failedEvents.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.app_id === value.app_id
        ))
      )
    }

    // filter out processed answers as well as any formerly failed answers that have come back as successful
    events = events.filter((event) => !successfulResponses.includes(event.app_id) && !failedResponses.includes(event.app_id))
    failedEvents = failedEvents.filter((event) => !(successfulResponses.includes(event.app_id)))

    localStorage.setItem("answers", JSON.stringify(events))
    localStorage.setItem("failed_answers", JSON.stringify(failedEvents))
  }

  removeSuccessfulPhotos(data, direction) {
    for (let index in data) {
      const key = Object.keys(data[index])[0]

      // We'll ignore failures for now and just always remove photos from localStorage after being seen by the api
      localStorage.removeItem(`${direction}photo${key}`)
      if (direction === "arriving") {
        // handle arrival photos stored under the old key
        localStorage.removeItem(`photo${key}`)
      }
    }
  }

  responseBlockContainsFalse(responseBlock) {
    for (let answer_app_id in responseBlock[Object.keys(responseBlock)[0]]) {
      if (responseBlock[Object.keys(responseBlock)[0]][answer_app_id] === false) {
        return true
      }
    }
    return false
  }

  collectArrivalPhotos() {
    var i = 0,
    objectArray = [],
    sKey,
    totalPhotos;
    // eslint-disable-next-line
    for (; sKey = localStorage.key(i); i++) {
      if (sKey.slice(0, 13) === "arrivingphoto") {
        objectArray.push({
          app_id: sKey.slice(13),
          photo_data: localStorage.getItem(sKey)
        });
        totalPhotos++
      }
      else if (sKey.slice(0, 5) === "photo") {
        objectArray.push({
          app_id: sKey.slice(5),
          photo_data: localStorage.getItem(sKey)
        });
        totalPhotos++
      }
      else if (sKey.slice(0, 12) === "leavingphoto") {
        totalPhotos++
      }
    }

    // if more than 20 photos are being stored locally at the moment, we should take no more photos
    if (totalPhotos > 19) {
      localStorage.setItem("too_many_photos", "true")
    }
    // if less than 20 photos are being stored locally at the moment, we can take photos again
    else {
      localStorage.removeItem("too_many_photos")
    }

    return objectArray
  }

  collectDeparturePhotos() {
    var i = 0,
    objectArray = [],
    sKey;

    // eslint-disable-next-line
    for (; sKey = localStorage.key(i); i++) {
      if (sKey.slice(0, 12) === "leavingphoto") {
        objectArray.push({
          app_id: sKey.slice(12),
          photo_data: localStorage.getItem(sKey)
        });
      }
    }

    return objectArray
  }

  calculateLocalStorageSize() {
    let hash = {}
    hash["total"] = 0

    for (const key in localStorage) {
      // Skip over localStorage functions
      if (["clear", "getItem", "key", "length", "removeItem", "setItem"].includes(key)) {
        continue
      }

      if (localStorage.getItem(key) === null) {
        continue
      }

      hash[key] = localStorage.getItem(key).length
      hash["total"] += hash[key]
    }

    return hash
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    let arrivals, arriving_photos, visiting, covid_results, departures, leaving_photos, offlineEmergencyAccessAttempts, custom_attributes, answers;

    // introducing a hard limit of events sent in a single request
    const maxRecords = 100;
    const maxPhotos = 5;
    // Every photo we send will be very large, so to keep requests small we will treat each photo as a large chunk of the total number of records we send to the api
    const photoRecordWeight = Math.floor(maxRecords/maxPhotos)

    arrivals = ( JSON.parse(localStorage.getItem("arrivals")) || [] ).slice(0, maxRecords)
    let eventCount = arrivals.length

    if (eventCount < maxRecords) {
      arriving_photos = this.collectArrivalPhotos().slice(0, Math.ceil((maxRecords - eventCount) / photoRecordWeight))
      eventCount += arriving_photos.length * photoRecordWeight
    }

    if (eventCount < maxRecords) {
      visiting = ( JSON.parse(localStorage.getItem("visiting")) || [] ).slice(0, maxRecords - eventCount)
      eventCount += visiting.length
    }

    if (eventCount < maxRecords) {
      covid_results = ( JSON.parse(localStorage.getItem("covid_results")) || [] ).slice(0, maxRecords - eventCount)
      eventCount += covid_results.length
    }

    if (eventCount < maxRecords) {
      departures = ( JSON.parse(localStorage.getItem("departures")) || [] ).slice(0, maxRecords - eventCount)
      eventCount += departures.length
    }

    if (eventCount < maxRecords) {
      leaving_photos = this.collectDeparturePhotos().slice(0, Math.ceil((maxRecords - eventCount) / photoRecordWeight))
      eventCount += leaving_photos.length * photoRecordWeight
    }

    if (eventCount < maxRecords) {
      offlineEmergencyAccessAttempts = ( JSON.parse(localStorage.getItem("offline_emergency_access_attempts")) || [] ).slice(0, maxRecords - eventCount)
      eventCount += offlineEmergencyAccessAttempts.length
    }

    if (eventCount < maxRecords) {
      custom_attributes = ( JSON.parse(localStorage.getItem("custom_attributes")) || [] ).slice(0, maxRecords - eventCount)
      eventCount += custom_attributes.length
    }

    if (eventCount < maxRecords) {
      answers = ( JSON.parse(localStorage.getItem("answers")) || [] ).slice(0, maxRecords - eventCount)
      eventCount += answers.length
    }

    // if event count is still under 1 thousand, add in previously failed events
    let failedArrivals, failedVisiting, failedCovidResults, failedDepartures, failedOfflineEmergencyAccessAttempts, failedCustomAttributes, failedAnswers;
    if (eventCount < maxRecords) {
      failedArrivals = ( JSON.parse(localStorage.getItem("failed_arrivals")) || [] ).slice(0, maxRecords - eventCount)
      arrivals = arrivals.concat(failedArrivals)
      eventCount += failedArrivals.length
    }

    if (eventCount < maxRecords) {
      failedVisiting = ( JSON.parse(localStorage.getItem("failed_visiting")) || [] ).slice(0, maxRecords - eventCount)
      visiting = visiting.concat(failedVisiting)
      eventCount += failedVisiting.length
    }

    if (eventCount < maxRecords) {
      failedCovidResults = ( JSON.parse(localStorage.getItem("failed_covid_results")) || [] ).slice(0, maxRecords - eventCount)
      covid_results = covid_results.concat(failedCovidResults)
      eventCount += failedCovidResults.length
    }

    if (eventCount < maxRecords) {
      failedDepartures = ( JSON.parse(localStorage.getItem("failed_departures")) || [] ).slice(0, maxRecords - eventCount)
      departures = departures.concat(failedDepartures)
      eventCount += failedDepartures.length
    }

    if (eventCount < maxRecords) {
      failedOfflineEmergencyAccessAttempts = ( JSON.parse(localStorage.getItem("failed_offline_emergency_access_attempts")) || [] ).slice(0, maxRecords - eventCount)
      offlineEmergencyAccessAttempts = offlineEmergencyAccessAttempts.concat(failedOfflineEmergencyAccessAttempts)
      eventCount += offlineEmergencyAccessAttempts.length
    }

    if (eventCount < maxRecords) {
      failedCustomAttributes = ( JSON.parse(localStorage.getItem("failed_custom_attributes")) || [] ).slice(0, maxRecords - eventCount)
      custom_attributes = custom_attributes.concat(failedCustomAttributes)
      eventCount += failedCustomAttributes.length
    }

    if (eventCount < maxRecords) {
      failedAnswers = ( JSON.parse(localStorage.getItem("failed_answers")) || [] ).slice(0, maxRecords - eventCount)
      answers = answers.concat(failedAnswers)
      eventCount += failedAnswers.length
    }

    var json = JSON.stringify({
      "results": {
        "device_time": formatDate(new Date()),
        "git_version": process.env.REACT_APP_COMMIT_HASH,
        "dumped_localstorage": localStorage.getItem("dumped_localstorage") === "true",
        "arrivals": arrivals || [],
        "arriving_photos": arriving_photos || [],
        "custom_attributes": custom_attributes || [],
        "covid_results": covid_results || [],
        "visiting": visiting || [],
        "departures": departures || [],
        "leaving_photos": leaving_photos || [],
        "answers": answers || [],
        "offline_emergency_access_attempts": offlineEmergencyAccessAttempts || [],
        "too_many_photos": localStorage.getItem("too_many_photos") === "true",
        "rejected_photos": localStorage.getItem("rejectedPhotos") || 0,
        "localstorage_size": this.calculateLocalStorageSize()
      },
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/push`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
          return "unauthorized"
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (data !== "unauthorized") {
          this.removeSuccessfulEvents(data["arrivals"], "arrivals")
          this.removeSuccessfulEvents(data["custom_attributes"], "custom_attributes")
          this.removeSuccessfulEvents(data["covid_results"], "covid_results")
          this.removeSuccessfulEvents(data["visiting"], "visiting")
          this.removeSuccessfulEvents(data["departures"], "departures")
          this.removeSuccessfulEvents(data["offline_emergency_access_attempts"], "offline_emergency_access_attempts")
          this.removeSuccessfulAnswers(data["answers"])
          this.removeSuccessfulPhotos(data["arriving_photos"], "arriving")
          this.removeSuccessfulPhotos(data["leaving_photos"], "leaving")

          // Once the api knows localStorage has been cleared, remove that flag from the device
          localStorage.removeItem("dumped_localstorage")

          this.props.handleFetchSuccess()
        }

      })
      .catch(error => {
        this.props.handleFetchFailure()
        this.setState({ error })
      })
  }
}

export default SendResults;
