import React, { Component } from 'react';
import SetTitle from '../components/communal/SetTitle';

class Version extends Component {
  render() {
    return (
      <div className="main-page">
        <SetTitle title={"Version"} />

        <div className="content">
          <div className="background">
            <div className="message">
              Running version: <strong>{process.env.REACT_APP_COMMIT_HASH}</strong><br />
              Last build date: <strong>{process.env.REACT_APP_BUILD_DATE}</strong>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Version;
