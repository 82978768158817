import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import GenerateQRCode from '../home/GenerateQRCode';

class ArrivingOrLeavingComponent extends Component {
  render() {
    let profile = this.props.profile

    if (profile === undefined) {
      // Profile hasn't been selected, so the Device has been configured to show Arriving or Leaving option first
      return (
        <>
          <div className="widgets" style={{gridTemplateColumns: "auto auto", gridGap: "80px"}}>
            <Link to={{ pathname: '/profiles', state: { direction: "arriving" } }} className="widget arriving-or-leaving">
              Arriving
            </Link>

            <Link to={{ pathname: '/profiles', state: { direction: "leaving" } }} className="widget arriving-or-leaving">
              Leaving
            </Link>
          </div>

          <div className="single-qr-container">
            Mobile Check in/out
            <GenerateQRCode />
          </div>
        </>
      )
    }
    else {
      return (
        <div className="widgets"  style={{gridTemplateColumns: "auto auto"}}>
          <Link to={{ pathname: '/arriving', state: { profile: profile } }} className="widget">
            Arriving
          </Link>

          <Link to={{ pathname: '/leaving', state: { profile: profile } }} className="widget">
            Leaving
          </Link>
        </div>
      )
    }
  }
}

export default ArrivingOrLeavingComponent;
