import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

class Logout extends Component {
  render() {
    let cookies = new Cookies();
    cookies.remove("device_token", { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN })

    return <Redirect to="/"/>
  }
}

export default Logout;
