import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';
import HeaderText from '../../components/communal/HeaderText';

import { handleChange } from '../../utilities/generic/Forms.js'
import { formatDate, generateId, fetchWithTimeout, filterUnique } from '../../utilities/generic/Common.js'

class Emergency extends Component {
  constructor(props) {
    super(props);

    this.handleChange = handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.buildFireLog = this.buildFireLog.bind(this);
  }

  state = {
    fire_log_access_pin: "",

    fire_log: null,
    webFireLog: null,

    checked_ids: [],

    unauthorized: false,
    error: ""
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let checked = this.state.checked_ids.includes(name);

    if (checked) {
      this.setState(prevState => ({
        checked_ids: prevState.checked_ids.filter(element => element !== name)
      }))
    }
    else {
      this.setState(prevState => ({
        checked_ids: prevState.checked_ids.concat(name)
      }))
    }
  }

  handleSubmit(event) {
    const currentlyOnline = navigator.onLine;

    if (currentlyOnline) {

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      timeout: 10000,
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetchWithTimeout(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/fire_log?fire_log[access_pin]=${this.state.fire_log_access_pin}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        // server returns 403 with incorrect PIN: they do not need to log in again
        else if (response.status === 403) {
          return response.json();
        }
        // server returns 401 with invalid credentials: they must log in again
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
          return "failure"
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (data.errors) {
          this.setState({error: data.errors})
        }
        else if (data !== "failure") {
          localStorage.removeItem("concurrent_pin_failures")
          this.setState({
            fire_log: data,
            webFireLog: true,
            error: "",
          })
        }
      })
      .catch(error => {
        this.setState({error: error.message})
        this.buildFireLog();
      })
    }
    else {
      this.buildFireLog()
    }

    event.preventDefault()
  }

  validPin() {
    // To accept a pin input, the entered pin must match the localStorage entry. There must also have been less than 4 failed attempts since the last time the Fire Log was accessed
    const concurrentFailures = JSON.parse(localStorage.getItem("concurrent_pin_failures")) || 0
    return this.state.fire_log_access_pin === localStorage.getItem("fire_log_access_pin") && concurrentFailures < 4
  }

  buildFireLog() {
    const attemptSuccess = this.validPin();

    // regardless of success, save emergency access attempt to localStorage to send to the api when the device reconects
    let attempts = JSON.parse(localStorage.getItem("offline_emergency_access_attempts")) || []
    let attempt = {
      "success": attemptSuccess,
      "time": formatDate(new Date()),
      "app_id": generateId(),
      "version": process.env.REACT_APP_COMMIT_HASH
    }

    attempts.push(attempt)
    localStorage.setItem("offline_emergency_access_attempts", JSON.stringify(attempts))

    if (attemptSuccess) {
      // Remove concurrent fails counter on success
      localStorage.removeItem("concurrent_pin_failures")
    }
    else {
      // Increment concurrent fails counter on failure, then return with error message
      let concurrentFailures = JSON.parse(localStorage.getItem("concurrent_pin_failures")) + 1 || 1
      localStorage.setItem("concurrent_pin_failures", concurrentFailures)

      let errorMessage = concurrentFailures < 4 ? "Invalid PIN" : "Too many PIN failures, please reconnect the device to the internet and try again"
      return this.setState({error: errorMessage})
    }

    let fire_log = {
      visitors: [],
      residents: [],
      staff: [],
      contractors: [],
      industry_professionals: [],
      other: []
    }

    // grab data from localStorage
    const checked_in_people = JSON.parse(localStorage.getItem("checked_in_people")) || []
    const people = JSON.parse(localStorage.getItem("people")) || []

    const arrivals = JSON.parse(localStorage.getItem("arrivals")) || []
    const departures = JSON.parse(localStorage.getItem("departures")) || []
    const visited_people = JSON.parse(localStorage.getItem("visiting")) || []

    let arrivalIds = filterUnique(arrivals.map(arrival => arrival.person_id))
    let departureIds = filterUnique(departures.map(departure => departure.person_id))

    // get ids of people that have both arrived and departed
    const arrivalAndDepartureIds = arrivalIds.filter(arrivalId => departureIds.includes(arrivalId))

    if (arrivalAndDepartureIds.length > 0) {
      // any database person who has arrived but not departed is on site
      arrivalIds = arrivalIds.filter(arrivalId => !arrivalAndDepartureIds.includes(arrivalId))

      // any database person who has departed but not arrived is off site
      departureIds = departureIds.filter(departureId => !arrivalAndDepartureIds.includes(departureId))

      arrivalAndDepartureIds.forEach((id) => {
        let lastArrivalTime = new Date(arrivals.findLast(arrival => arrival.person_id === id).check_in_time)
        let lastDepartureTime = new Date(departures.findLast(departure => departure.person_id === id).check_out_time)

        // if a database person's latest action is an arrival, they are on site
        if (lastArrivalTime > lastDepartureTime) {
          arrivalIds.push(id)
        }
        // if a database person's latest action is a depature, they are off site
        else {
          departureIds.push(id)
        }

      })
    }

    let arrivalData = {}

    arrivalIds.forEach((id) => {
      let lastArrival = arrivals.findLast(arrival => arrival.person_id === id)
      arrivalData[id] = {
        check_in: lastArrival.check_in_time,
        app_id: lastArrival.app_id
      }
    })

    const onSiteIds = filterUnique(checked_in_people.filter(id => !departureIds.includes(id)).concat(arrivalIds))
    const onSitePeople = people.filter(person => onSiteIds.includes(person.id))

    fire_log.visitors = this.mapPeopleToFireLog(onSitePeople.filter(person => person.type_of_person === "visitor"), arrivalData, visited_people, people)
    fire_log.residents = this.mapPeopleToFireLog(onSitePeople.filter(person => person.type_of_person === "resident"), arrivalData, visited_people, people)
    fire_log.staff = this.mapPeopleToFireLog(onSitePeople.filter(person => person.type_of_person === "staff"), arrivalData, visited_people, people)
    fire_log.contractors = this.mapPeopleToFireLog(onSitePeople.filter(person => person.type_of_person === "contractor"), arrivalData, visited_people, people)
    fire_log.industry_professionals = this.mapPeopleToFireLog(onSitePeople.filter(person => person.type_of_person === "industry professional"), arrivalData, visited_people, people)
    fire_log.other = this.mapPeopleToFireLog(onSitePeople.filter(person => person.type_of_person === "other"), arrivalData, visited_people, people)

    // then we interpret non-database arrivals and departures.

    const unknownArrivals = arrivals.filter(arrival => arrival.person_id === undefined)
    let unknownArrivalNames = filterUnique(unknownArrivals.map(arrival => `${arrival.first_name.toLowerCase()} ${arrival.last_name.toLowerCase()}`))

    const departureNames = filterUnique(departures.map(departure => `${departure.first_name.toLowerCase()} ${departure.last_name.toLowerCase()}`))

    const unknownArrivalsAndDepartureNames = unknownArrivalNames.filter(arrivalName => departureNames.includes(arrivalName))

    if (unknownArrivalsAndDepartureNames.length > 0) {
      // any non database person who has arrived but not departed is on site
      unknownArrivalNames = unknownArrivalNames.filter(arrivalName => !departureNames.includes(arrivalName))

      unknownArrivalsAndDepartureNames.forEach((name) => {
        let lastArrivalTime = new Date(arrivals.findLast(arrival => `${arrival.first_name.toLowerCase()} ${arrival.last_name.toLowerCase()}` === name).check_in_time)
        let lastDepartureTime = new Date(departures.findLast(departure => `${departure.first_name.toLowerCase()} ${departure.last_name.toLowerCase()}` === name).check_out_time)

        // if a non database person's latest action is an arrival, they are on site
        if (lastArrivalTime > lastDepartureTime) {
          unknownArrivalNames.push(name)
        }
      })
    }

    unknownArrivalNames.forEach((name) => {
      let arrival = unknownArrivals.findLast(arrival => `${arrival.first_name.toLowerCase()} ${arrival.last_name.toLowerCase()}` === name)

      fire_log = this.addArrivalToFireLog(arrival, fire_log, visited_people, people)
    })

    this.setState({
      fire_log: fire_log,
      webFireLog: false
    })
  }

  mapPeopleToFireLog(people, arrivalData, visited_people) {
    people.forEach((person) => {
      let check_in, visited_people;

      if (arrivalData[person.id]) {
        check_in = this.evaluateCheckInTime(arrivalData[person.id].check_in)
        visited_people = this.findVisitedPeople(arrivalData[person.id].app_id, visited_people, people)
      }

      person.check_in = check_in || "unknown"
      person.visited_people = visited_people || []
    })

    return people
  }

  addArrivalToFireLog(arrival, fire_log, visited_people, people) {
    if (arrival.type_of_person === "industry professional") {
      arrival.type_of_person = "industry_professionals"
    }
    else if (arrival.type_of_person !== "staff" && arrival.type_of_person !== "other") {
      arrival.type_of_person += "s"
    }

    // only append arrival to fire log if this person's id is not already represented
    if (arrival.person_id === undefined || fire_log[arrival.type_of_person].filter(person => person.id === arrival.person_id).length === 0) {
      let person = {
        id: arrival.person_id || `unknown${arrival.type_of_person}${fire_log[arrival.type_of_person].length + 1}`,
        first_name: arrival.first_name,
        last_name: arrival.last_name,
        phone: arrival.phone,
        check_in: this.evaluateCheckInTime(arrival.check_in_time),
        visited_people: this.findVisitedPeople(arrival.app_id, visited_people, people)
      }

      fire_log[arrival.type_of_person] = fire_log[arrival.type_of_person].concat([person])
    }

    return fire_log
  }

  evaluateCheckInTime(time) {
    const difference = new Date().getTime() - new Date(time).getTime()

    if (difference < 60000) {
      return "Less than a minute ago"
    }

    if (difference < 3600000) {
      return `${Math.round(difference/60000)} minutes ago`
    }

    if (difference < 86400000) {
      return `${Math.round(difference/3600000)} hours ago`
    }

    return `${Math.round(difference/86400000)} days ago`
  }

  findVisitedPeople(app_id, visited_people, people) {
    let fire_log_visited_people = []
    let selected_visited_people_block = visited_people.findLast(visited_people_block => visited_people_block.arriving_app_id === app_id)

    if (selected_visited_people_block) {
      let visited_people_from_ids = people.filter(person => selected_visited_people_block.visited_people_ids.includes(person.id))

      visited_people_from_ids.forEach((visited_person) => {
        fire_log_visited_people.push({id: visited_person.id, name: visited_person.name})
      })

      selected_visited_people_block.typed_names.forEach((visited_person, index) => {
        fire_log_visited_people.push({id: visited_person.person_id || `unknown${index}`, name: visited_person.typed_name})
      })
    }

    return fire_log_visited_people
  }

  renderTypeOfPersonBlock(type_of_person, people) {
    if (people.length > 0) {
      return (
        <div>
          <h3 className="widget-title">{type_of_person}</h3>
          {people.map((person) => (
            <div className="fire-log-row" key={person.id}>
              <input className="column" type="checkbox" id={`${type_of_person}-${person.id}`} name={person.id} onChange={this.handleCheckbox} checked={this.state.checked_ids.includes(person.id.toString())} />
              <label for={`${type_of_person}-${person.id}`}>
                <strong>{`${person.first_name || ""} ${person.last_name || ""}`}</strong>
                {this.renderCheckIn(person.check_in)}
                {this.renderPhone(person.phone)}
                {this.renderVisitedPeople(person.visited_people)}
              </label>
            </div>
          ))}
        </div>
      )
    }
  }

  renderCheckIn(check_in) {
    if (check_in && check_in !== "Resident" && check_in !== "unknown") {
      return (
        <span> - Check In: {check_in}</span>
      )
    }
  }

  renderPhone(phone) {
    if (phone)
      return (
        <span> - Phone Number: {phone}</span>
      )
  }

  renderVisitedPeople(visited_people) {
    if (visited_people.length > 0)
      return (
        <>
          <span> - Visited People: {visited_people[0].name}</span>
          {visited_people.length > 1 && visited_people.slice(1).map((visited_person) => (
            <span key={visited_person.id}>, {visited_person.name}</span>
          ))}
        </>
      )
  }

  renderOffline(webFireLog) {
    return webFireLog ? "" : "(Offline)"
  }

  renderOfflineNotice(webFireLog) {
    if (webFireLog === false) {
      return (
        <>
          <div>Note that the device is currently running in offline mode.</div>
          <div>Check ins & check outs submitted on other devices while this device has been offline will not be represented here.</div>
          <div>Please reconnect the device (as well as any other disconnected devices) to the internet to ensure maximum accuracy.</div>
        </>
      )
    }
  }

  render() {
    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined || token === "undefined"){
      return <Redirect to="/setup/user"/>
    }

    const { fire_log_access_pin, fire_log, webFireLog, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/setup/user"/>
    }

    if (fire_log) {
      return (
        <div className="main-page two-back-buttons">
          <InteractionTimeout seconds={600} />

          <SetTitle title={"Emergency Fire Log"} />
          <HeaderText />

          <div className="top-back">
            <Link to="/" className="widget">
              Back
            </Link>
          </div>

          <div className="content">
            <div className="widget fire-log">
              <h2>Fire Log {this.renderOffline(webFireLog)}</h2>
              {this.renderOfflineNotice(webFireLog)}
              {this.renderTypeOfPersonBlock("Visitors", fire_log.visitors)}
              {this.renderTypeOfPersonBlock("Residents", fire_log.residents)}
              {this.renderTypeOfPersonBlock("Staff", fire_log.staff)}
              {this.renderTypeOfPersonBlock("Contractors", fire_log.contractors)}
              {this.renderTypeOfPersonBlock("Industry Professionals", fire_log.industry_professionals)}
              {this.renderTypeOfPersonBlock("Other", fire_log.other)}
            </div>
          </div>

          <div className="bottom-back">
            <Link to="/" className="widget">
              Back
            </Link>
          </div>
        </div>
      )
    }
    return (
      <div className="main-page back-button">
        <InteractionTimeout seconds={60} />

        <SetTitle title={"Emergency"} />
        <HeaderText />

        <div className="content">
          <div className="widget form">
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <div className="message">
                Enter the PIN:
              </div>

              <label>
                <div className="text"> PIN:</div>
                <input autoFocus className="password-font" type="text" name="fire_log_access_pin" value={fire_log_access_pin} autoComplete="off" inputMode="numeric" maxLength="4" onChange={this.handleChange} />
                <br/>
                <div className="blank"></div>
              </label>

              <div className="red">{error}</div>
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>

        <div className="back">
          <Link to="/" className="widget">
            Back
          </Link>
        </div>
      </div>
    )
  }
}

export default Emergency;
