import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { validateLocalStorage } from './utilities/generic/Common'

import UserSetup from './pages/setup/User';
import LocationSetup from './pages/setup/Location';
import DeviceSetup from './pages/setup/Device';
import CameraSetup from './pages/setup/Camera';

import Home from './pages/Home';
import Blank from './pages/Blank';
import Refresh from './pages/Refresh';
import Logout from './pages/Logout';

import Emergency from './pages/communal/Emergency';

import ProfilesCommunal from './pages/communal/Profiles';
import ArrivingOrLeavingCommunal from './pages/communal/ArrivingOrLeaving';
import ArrivingCommunal from './pages/communal/Arriving';
import VisitingWhoCommunal from './pages/communal/VisitingWho';
import CustomAttributesCommunal from './pages/communal/CustomAttributes';
import LeavingCommunal from './pages/communal/Leaving';
import FeedbackCommunal from './pages/communal/Feedback';
import CovidQRCommunal from './pages/communal/CovidQR';
import InformationCommunal from './pages/communal/Information';
import ThankYouCommunal from './pages/communal/ThankYou';

import HomePersonal from './pages/personal/Home';
import ProfilesPersonal from './pages/personal/Profiles';
import ArrivingOrLeavingPersonal from './pages/personal/ArrivingOrLeaving';
import ArrivingPersonal from './pages/personal/Arriving';
import VisitingWhoPersonal from './pages/personal/VisitingWho';
import CustomAttributesPersonal from './pages/personal/CustomAttributes';
import LeavingPersonal from './pages/personal/Leaving';
import FeedbackPersonal from './pages/personal/Feedback';
import CovidQRPersonal from './pages/personal/CovidQR';
import InformationPersonal from './pages/personal/Information';
import ThankYouPersonal from './pages/personal/ThankYou';
import Error404Personal from './pages/personal/Error404';

import LocalStorage from './pages/LocalStorage';
import Version from './pages/Version';
import Error404 from './pages/Error404';

class App extends Component {

  backgroundImage(background_image) {
    if (localStorage !== null) {
      background_image = localStorage.getItem("background_image")

      if (background_image !== null) {
        return (
          `url(${background_image})`
        );
      }
    }
  }

  render() {
    if (validateLocalStorage().result === false) {
      return (
        <Router>
          <Switch>
            {/* Set up LocalStorage page, so we can Redirect to it before hitting the `this.backgroundImage()` call that needs localStorage to be enabled. */}
            <Route exact path='/localstorage' component={LocalStorage} />
          </Switch>

          <Redirect to="/localstorage"/>
        </Router>
      )
    }

    return (
      <div className="container full-background" style={{ backgroundImage: this.backgroundImage() }}>
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/blank' component={Blank} />
            <Route exact path='/refresh' component={Refresh} />
            <Route exact path='/logout' component={Logout} />

            <Route path='/setup/user' component={UserSetup} />
            <Route path='/setup/location' component={LocationSetup} />
            <Route path='/setup/device' component={DeviceSetup} />
            <Route path='/setup/camera' component={CameraSetup} />

            <Route exact path='/emergency' component={Emergency} />

            <Route exact path='/profiles' component={ProfilesCommunal} />
            <Route exact path='/arriving-or-leaving' component={ArrivingOrLeavingCommunal} />
            <Route exact path='/arriving' component={ArrivingCommunal} />
            <Route exact path='/custom-attributes' component={CustomAttributesCommunal} />
            <Route exact path='/covid-qr' component={CovidQRCommunal} />
            <Route exact path='/visiting-who' component={VisitingWhoCommunal} />
            <Route exact path='/leaving' component={LeavingCommunal} />
            <Route exact path='/feedback' component={FeedbackCommunal} />
            <Route exact path='/information' component={InformationCommunal} />
            <Route exact path='/thank-you' component={ThankYouCommunal} />

            <Route exact path='/personal/new/:token' component={HomePersonal} />
            <Route exact path='/personal/profiles' component={ProfilesPersonal} />
            <Route exact path='/personal/arriving-or-leaving' component={ArrivingOrLeavingPersonal} />
            <Route exact path='/personal/arriving' component={ArrivingPersonal} />
            <Route exact path='/personal/custom-attributes' component={CustomAttributesPersonal} />
            <Route exact path='/personal/covid-qr' component={CovidQRPersonal} />
            <Route exact path='/personal/visiting-who' component={VisitingWhoPersonal} />
            <Route exact path='/personal/leaving' component={LeavingPersonal} />
            <Route exact path='/personal/feedback' component={FeedbackPersonal} />
            <Route exact path='/personal/information' component={InformationPersonal} />
            <Route exact path='/personal/thank-you' component={ThankYouPersonal} />
            <Route exact path='/personal/404' component={Error404Personal} />

            <Route exact path='/localstorage' component={LocalStorage} />
            <Route exact path='/version' component={Version} />
            <Route exact path='/404' component={Error404} />

            <Route component={Error404} />
          </Switch>
        </Router>
      </div>
    )
  }

  componentDidMount() {
    document.body.style.overflow = "hidden"
  }
}

export default App;
