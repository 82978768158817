import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Honeybadger from '@honeybadger-io/js';

import HeaderText from '../../components/personal/HeaderText';
import SetTitle from '../../components/personal/SetTitle';

import ProfilesComponent from '../../components/personal/Profiles';

class Profiles extends Component {
  constructor(props) {
    super(props)

    this.pullAndRedirect = this.pullAndRedirect.bind(this)
  }

  state = {
    profile: null,
    error: null
  };

  pullAndRedirect(event) {
    const profile_id = event.target.value;
    const type_of_person = event.target.dataset.typeOfPerson;
    const token = this.props.location.state.token

    // Once Profile has been selected in the component, we need to fetch the Pull data before redirecting on to the direction pages (Arriving/Leaving)
    //
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "personal": {
        "token": token,
        "profile_id": profile_id,
        "type_of_person": type_of_person
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/pull`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          errorsInResponse = true
          return response.json();
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (errorsInResponse) {
          this.setState({
            errors: data
          })
        }
        else {
          Honeybadger.setContext({
            profileName: data.name
          })
          this.setState({
            profile: data,
            redirect: true
          })
        }
      })
      .catch(error => this.setState({ error: error }))
  }

  render() {
    const token = this.props.location.state.token
    const profiles = this.props.location.state.profiles
    const location = this.props.location.state.location
    const direction = this.props.location.state.direction

    const { redirect, profile, error, errors } = this.state;

    if (redirect === true) {
      // Use Redirect if Profile select leads to Direction page
      return <Redirect to={{ pathname: `/personal/${direction}`, state: { profile: this.state.profile, direction: direction, token: token, previous_page: "profiles" } }} />
    }

    if (errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    return (
      <div className="main-page">
        <SetTitle title={"Arriving or Leaving"} profile={profile} />
        <HeaderText message={location.welcome_message} />

        <div className="content">
          <div className="background">
            <div className="message">
              {location.text_on_select}
            </div>
            <ProfilesComponent token={token} direction={direction} profiles={profiles} location={location} pullAndRedirect={this.pullAndRedirect}/>
          </div>
        </div>

        <Link to={{ pathname: `/personal/new/${encodeURIComponent(token)}`, search: "?page=home" }} className="widget">
          Back
        </Link>
      </div>
    );
  }
}

export default Profiles;
