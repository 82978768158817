import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../shared/Logo';

class HeaderText extends Component {
  render() {

    var welcomeMessage = JSON.parse(localStorage.getItem("location")).welcome_message

    if (welcomeMessage === "") {
      welcomeMessage = "Welcome to Digital Reception"
    }

    let logo = null

    if (localStorage !== null) {
      logo = localStorage.getItem("logo")
    }

    const className = (logo === null || logo === "null") ? "header" : "header with-logo";
    const size = this.props.size || "";

    if (logo === "null" && size !== "big") {
      // No logo and no message. No need to render header
      return <div class="blank-header"></div>
    }

    if (this.props.hyperlink === false) {
      return (
        <div className={`${className} ${size}`}>
          <Logo size={size} />
          {size === "big" && welcomeMessage}
        </div>
      )
    }
    else {
      return (
        <div className={`${className} ${size}`}>
          <Link to={'/'}>
            <Logo size={size} />
            {size === "big" && welcomeMessage}
          </Link>
        </div>
      )
    }
  }
}

export default HeaderText;
