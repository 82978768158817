import Honeybadger from '@honeybadger-io/js'

const zlib = require('pako');
var cbor = require('cbor');
const base45 = require('base45-js');

export function handleCovidPassExpiryDate(data) {
  var expiryDate = null
      // the prefix check works as a basic integrity check to try to make sure that a covid pass QR code is what is being scanned by the reader
      // and needs to be removed for further parsing anyway
  if (data.startsWith('HC1')) {
    data = data.substring(3)
    if (data.startsWith(':')) {
      data = data.substring(1)

      try {
        // after prefix removal, the data is still base45 encoded and zlib deflated, so it needs to be decoded then inflated
        var base45DecodedData = base45.decode(data)

        try {
          var zlibInflatedData = zlib.inflate(base45DecodedData)

          try {
            // after decoding and inflation we are left with a CWT which needs to be CBOR decoded into its constituant parts
            var results = cbor.decodeAllSync(zlibInflatedData);

            try {
              // index [2] is where the data payload lies so we need to isolate that
              var cborData = results[0].value[2]

              try {
                // that payload is still CBOR encoded so we need to CBOR decode again
                var greenpassData = cbor.decodeAllSync(cborData);
              }
              catch(err) {
                Honeybadger.setContext({
                  base45Encoded: data,
                  base45Decoded: base45DecodedData,
                  zlibInflatedData: zlibInflatedData,
                  initialCborDecoded: results,
                  isolatedCborEncodedData: cborData
                })
                Honeybadger.notify(err)
                return "Failed to cbor decoded the isolated cbor payload"
              }
            }
            catch(err) {
              Honeybadger.setContext({
                base45Encoded: data,
                base45Decoded: base45DecodedData,
                zlibInflatedData: zlibInflatedData,
                initialCborDecoded: results
              })
              Honeybadger.notify(err)
              return "Failed to isolate the cbor payload from the cbor decoded data"
            }
          }
          catch(err) {
            Honeybadger.setContext({
              base45Encoded: data,
              base45Decoded: base45DecodedData,
              zlibInflatedData: zlibInflatedData
            })
            Honeybadger.notify(err)
            return "Failed to cbor decode zlib inflated data"
          }
        }
        catch(err) {
          Honeybadger.setContext({
            base45Encoded: data,
            base45Decoded: base45DecodedData
          })
          Honeybadger.notify(err)
          return "Failed to zlib inflate base45 decoded data."
        }
      }
      catch(err) {
        Honeybadger.setContext({
          base45Encoded: data
        })
        Honeybadger.notify(err)
        return "Failed to base45 decode Covid Pass"
      }

      try {
        // 30 day passes appear to be Map Objects and they have both a date valid from and date valid until field stored in epoch time
        // though different countries appear to mix them up so we find the largest of the two numbers to get the expiry date
        if (greenpassData[0] instanceof Map) {
          if (greenpassData[0].get(-260).get(1)["v"] === undefined) {
            return "Please present a Vaccine related Covid Pass, not a Recovery or Test certificate."
          }

          else {
            let doseNumber = greenpassData[0].get(-260).get(1)["v"][0]["dn"]
            let totalDoses = greenpassData[0].get(-260).get(1)["v"][0]["sd"]

            if (doseNumber >= totalDoses) {
              var date1 = greenpassData[0].get(4)
              var date2 = greenpassData[0].get(6)
              expiryDate = date1 > date2 ? date1 : date2

              expiryDate = new Date(expiryDate*1000)
              return expiryDate
            }
            else {
              return "The vaccination represented by this QR code is not indicitive of acceptable immunity. Please present the QR code representing a later dose if you are able."
            }
          }
        }
        // 3 day passes appear to be more traditional Objects, and have their own expiry dates built in for us to use (24 hours too early for some reason)
        else {
          var du = new Date(greenpassData[0]["-260"]["1"]["d"][0]["du"])
          expiryDate = new Date(du.setDate(du.getDate()+1))
          return expiryDate
        }
      }
      catch(err) {
        Honeybadger.setContext({
          greenpassData: greenpassData[0]
        })
        Honeybadger.notify(err)
        return "An error occurred during the expiry date extraction process"
      }


    }
    else {
       console.log("Warning: unsafe HC1: header - update to v0.0.4");
    };
  }
  else {
    return "Failed to find Covid Pass"
  };
}
