import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'

import HeaderText from '../../components/personal/HeaderText';
import SetTitle from '../../components/personal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';

import { formatDate, generateId, debounce, scrollElementToTop } from '../../utilities/generic/Common.js'

import { handleRadio, getAnonymousQuestionIds, answerForm, anonymousMessage } from '../../utilities/generic/Forms.js'

library.add(fas, far)

class Feedback extends Component {

  constructor(props) {
    super(props);

    this.debouncedScrollElementToTop = debounce(scrollElementToTop, 1000);
    this.handleChange = this.handleChange.bind(this);
    this.handleRadio = handleRadio.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    answers: {},

    errors: "",
    error: ""
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let tag = event.target.tagName;

    if (this.state.answers[name] === value) {
      let answers = this.state.answers
      delete answers[name]

      this.setState({answers: answers})

    }
    else {
      this.setState(existingState => ({
        answers: {
          ...existingState.answers,
          [name]: value
        }
      }))
    }

    if (tag === "TEXTAREA") {
      this.debouncedScrollElementToTop(event.target)
    }
    else {
      scrollElementToTop(event.target)
    }
  }

  handleSubmit(event) {
    let anonymousQuestionIds = getAnonymousQuestionIds(this)

    let token = this.props.location.state.token

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = null

    var answersWithAppIds = {}

    for (const key in this.state.answers) {
      answersWithAppIds[generateId()] = {
        question_id: key,
        answer: this.state.answers[key]
      }
    }

    if (this.props.location.state.direction === "arriving") {
      json = JSON.stringify({
        "personal": {
          "token": token,
          "arriving_app_id": this.props.location.state.directional_app_id,
          "answers": answersWithAppIds,
          "anonymous_question_ids": anonymousQuestionIds,
          "app_id": generateId(),
          "version": process.env.REACT_APP_COMMIT_HASH,
          "created_at": formatDate(new Date()),
          "profile_id": this.props.location.state.profile.id,
          "type_of_person": this.props.location.state.profile.type_of_person
        }
      })
    }
    else {
      json = JSON.stringify({
        "personal": {
          "token": token,
          "leaving_app_id": this.props.location.state.directional_app_id,
          "answers": answersWithAppIds,
          "anonymous_question_ids": anonymousQuestionIds,
          "app_id": generateId(),
          "version": process.env.REACT_APP_COMMIT_HASH,
          "created_at": formatDate(new Date()),
          "profile_id": this.props.location.state.profile.id,
          "type_of_person": this.props.location.state.profile.type_of_person
        }
      })
    }

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/feedback`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 401 || response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ updated: true, errors: ""})
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  render() {
    if (this.props.location.state === undefined) {
      return <Redirect to="/personal/404" />
    }

    let profile = this.props.location.state.profile
    let direction = this.props.location.state.direction

    const { updated, errors, error } = this.state;

    if (updated) {
      return <DestinationSwitch current_page="feedback" profile={profile} direction={direction} personal={true} />
    }

    if (errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    let feedbackTypes = this.props.location.state.feedbackTypes

    return (
      <div className="main-page feedback-page">
        <SetTitle title={"Feedback"} profile={profile} />
        <HeaderText message={profile.welcome_message} />

        <div className="content">

          <div className="feedback">
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              {feedbackTypes.map((feedback) => {
                if (feedback.feedback_questions.length !== 0) {
                  return (
                    <div className="feedback-type" key={feedback.id}>
                      { anonymousMessage(feedback.anonymous) }

                      <div className="feedback-title">{feedback.display}</div>

                      {feedback.feedback_questions.map((question) => {
                        return answerForm(question, this)
                      })}
                    </div>
                  )
                }
                else {
                  return(null)
                }
              })}

              <div className="submit-container">
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>

        </div>
      </div>
    );
  }
}

export default Feedback;
