import React, { Component } from 'react';

import SetTitle from '../../components/personal/SetTitle';

class Error404 extends Component {
  render() {
    return (
      <div>
        <SetTitle title={"Page Not Found"} />

        <div className="error-page">
          <h2>Page not found</h2>

          <div>Sorry, that page doesn't seem to exist. Please scan the QR code again.</div>
        </div>
      </div>
    );
  }
}

export default Error404;
