import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';

import HeaderText from '../../components/communal/HeaderText';
import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';
import ProfilesComponent from '../../components/communal/Profiles';

class Profiles extends Component {
  render() {
    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    const direction = this.props.location.state.direction
    const location = JSON.parse(localStorage.location)

    return (
      <div className="main-page back-button">
        <InteractionTimeout seconds={60} />

        <SetTitle title={"Type of Person"} />
        <HeaderText />

        <div className="content">
          <div className="background">
            <div className="message">
              {location.text_on_select}
            </div>
          </div>
        </div>

        <ProfilesComponent direction={direction} />

        <div className="back">
          <Link to={{ pathname: '/' }} className="widget">Back</Link>
        </div>

        <div></div>
      </div>
    );
  }
}

export default Profiles;
