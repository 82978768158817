import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import HeaderText from '../../components/communal/HeaderText';
import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';

import { formatDate, generateId } from '../../utilities/generic/Common.js'
import { handleMultiCheckbox, skipOrSelectText, selectPersonText } from '../../utilities/generic/Forms.js'

class VisitingWho extends Component {
  constructor(props) {
    super(props);

    this.forceShowTypedNames = this.forceShowTypedNames.bind(this);
    this.handleAddTypedName = this.handleAddTypedName.bind(this);
    this.handleClearTypedName = this.handleClearTypedName.bind(this);
    this.handleTypedNameChange = this.handleTypedNameChange.bind(this);
    this.handleMultiCheckbox = handleMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    people_ids: [],
    typed_names: [""],
    forceShowTypedNames: false,

    validDetails: false
  }

  forceShowTypedNames(event) {
    event.preventDefault();

    this.setState({
      forceShowTypedNames: true
    })
  }

  handleTypedNameChange(event) {
    let chosenIndex = parseInt(event.target.name);
    let value = event.target.value;

    let typed_names = this.state.typed_names.map((typed_name, index) => {
      if (index === chosenIndex) {
        return value
      }
      else {
        return typed_name
      }
    })

    this.setState({typed_names: typed_names});
    event.preventDefault();
  }

  handleAddTypedName(event) {
    this.setState((prevState) => ({
      typed_names: [...prevState.typed_names, ""]
    }))

    let lastTextbox = document.getElementById(this.state.typed_names.length - 1);
    lastTextbox.scrollIntoView(true);

    event.preventDefault();
  }

  handleClearTypedName(event) {
    let chosenIndex = parseInt(event.target.parentNode.id);

    let typed_names = this.state.typed_names
    typed_names[chosenIndex] = "";

    this.setState({typed_names: typed_names});
    event.preventDefault();
  }

  handleSubmit(event) {
    if (this.state.people_ids.length > 0 || this.state.typed_names.join("").trim() !== "") {
      let typed_names = this.state.typed_names.filter(typed_name => typed_name.trim() !== "");
      let typed_names_object = typed_names.map((typed_name) => ({typed_name: typed_name, person_id: null}));

      let visited = {
        arriving_app_id: this.props.location.state.arriving_app_id,
        visited_people_ids: this.state.people_ids,
        typed_names: typed_names_object,
        app_id: generateId(),
        version: process.env.REACT_APP_COMMIT_HASH,
        created_at: formatDate(new Date())
      }

      var visiting = JSON.parse(localStorage.getItem("visiting"))

      // Create visiting array if it doesn't exist
      if (visiting === null) {
        visiting = []
      }

      // Append to visiting array, and save to semi-permanent storage
      visiting.push(visited)
      localStorage.setItem("visiting", JSON.stringify(visiting))

      this.setState({validDetails: true})
    }
    else {
      // continue to next page without saving any visiting details
      this.setState({validDetails: true})
    }

    event.preventDefault();
  }

  gatherRelationships(person) {
    const currentPerson = this.props.location.state.person
    let relationships = this.props.location.state.relationships || []

    // Append all People of the visit_all type, if enabled
    if (currentPerson.visit_all !== null && currentPerson.visit_all !== undefined) {
      let people = JSON.parse(localStorage.people)
      people = people.filter(person => person.type_of_person === currentPerson.visit_all)
      people = people.filter(person => person.id !== currentPerson.id) // Remove currentPerson

      relationships = relationships.concat(people)
    }

    return relationships
  }

  renderRelationships(relationships) {
    if (relationships.length > 0) {
      return (
        <>
          <div className="message">
            {selectPersonText(relationships)}
          </div>

          <div className="widgets">
            {relationships.map((relationship) => {
              return (
                <div key={relationship.id}>
                  <input type="checkbox" id={`relationship_${relationship.id}`} name={relationship.id} checked={this.state.people_ids.includes(relationship.id)} onChange={this.handleMultiCheckbox} />
                  <label htmlFor={`relationship_${relationship.id}`}>{relationship.full_name}</label>
                </div>
              )
            })}
          </div>
        </>
      )
    }
  }

  renderTypedNames(typed_names, relationships) {
    if (this.state.forceShowTypedNames || relationships.length === 0) {
      let context = this.state.people_ids.length === 0 && typed_names.join("") === "" ? "" : " else"
      return (
        <div>
          <div className="padding-bottom">Please enter the names of anyone{context} you are visiting</div>
          {typed_names.map((text_box, index) => {
            return (
              <div className="typed-name-container" key={index} id={index}>
                <input className="typed-name" placeholder="e.g. John Smith" type="text" name={index} value={text_box} autoComplete="off" onChange={this.handleTypedNameChange} />
                {this.renderClearButton()}
              </div>
            )
          })}
          <button className="typed-name-add-button" onClick={this.handleAddTypedName}>Add Person</button>
        </div>
      )
    }
    else {
      let context = this.state.people_ids.length === 0 ? "" : " also"
      return (
        <div className="widgets">
          <label onClick={this.forceShowTypedNames}>I am{context} visiting someone else</label>
        </div>
      )
    }
  }

  renderClearButton() {
    if (this.state.typed_names.length > 1) {
      return (
        <button className="typed-name-button" onClick={this.handleClearTypedName}>Clear</button>
      )
    }
  }

  renderSubmitButton(people_ids, typed_names, ask_visiting_people) {
    if (ask_visiting_people !== "enforce" || people_ids.length !== 0 || typed_names.join("") !== "") {
      return (
        <div className="form invisible">
          <input type="submit" value={skipOrSelectText(people_ids, typed_names)} />
        </div>
      )
    }

    return (
      <div className="form invisible">
        <input type="submit" value="This profile requires a name to be entered or selected" disabled={true} />
      </div>
    )
  }

  render() {
    const { people_ids, typed_names } = this.state;

    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    let profile = this.props.location.state.profile
    let arriving_app_id = this.props.location.state.arriving_app_id
    let direction = this.props.location.state.direction
    let person = this.props.location.state.person

    if (this.state.validDetails) {
      return <DestinationSwitch current_page="visiting-who" profile={profile} person={person} arriving_app_id={arriving_app_id} direction={direction} />
    }

    let relationships = this.gatherRelationships()

    return (
      <div className="main-page visiting-who-page">
        <InteractionTimeout seconds={60} />

        <SetTitle title={"Visiting Who"} />
        <HeaderText />

        <div className="content">
          <div className="background">
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              {this.renderRelationships(relationships)}
              {this.renderTypedNames(typed_names, relationships)}

              {this.renderSubmitButton(people_ids, typed_names, profile.ask_visiting_people)}
            </form>
          </div>
        </div>

        <div></div>
      </div>
    );
  }
}

export default VisitingWho;
