import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/react'

const config = {
  apiKey: '5d1a246c',
  environment: process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV,
  revision: process.env.REACT_APP_COMMIT_HASH
}

const honeybadger = Honeybadger.configure(config)

Honeybadger.beforeNotify((notice) => {
  // https://docs.honeybadger.io/lib/javascript/guides/reducing-noise/
  if (notice.name === "RangeError") {
    return false
  }

  // Seems to affect iOS 14, but haven't heard any customer complaints.
  if (notice.message === "Origin https://device.dreception.com is not allowed by Access-Control-Allow-Origin.") {
    return false
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorBoundary honeybadger={honeybadger}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
