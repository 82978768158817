import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';

import HeaderText from '../../components/communal/HeaderText';
import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';

class ThankYou extends Component {

  message() {
    var message = ""

    if (this.props.location.state.direction === "arriving") {
      message = JSON.parse(localStorage.getItem("location")).arrival_thank_you_message
    }
    else if (this.props.location.state.direction === "leaving") {
      message = JSON.parse(localStorage.getItem("location")).departure_thank_you_message
    }

    if (message === "") {
      message = "Thank you."
    }

    return message
  }

  render() {
    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    return (
      <Link to={'/'} className="main-page">
        <InteractionTimeout seconds={10} />

        <SetTitle title={"Thank You"} />
        <HeaderText hyperlink={false} />

        <div className="content">
          <div className="background">
            <div className="message">
              {this.message()}
            </div>
          </div>
        </div>

        <div></div>
      </Link>
    );
  }
}

export default ThankYou;
