import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EmergencyButton extends Component {
  render() {
    const fire_log_access_pin = localStorage.getItem("fire_log_access_pin");

    // only show EmergencyButton when a PIN has been set
    if (fire_log_access_pin && fire_log_access_pin.length === 4) {
      return (
        <Link to="/emergency" className={"emergency-button"}>Emergency</Link>
      )
    }
  }
}

export default EmergencyButton;
