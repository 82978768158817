import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import BigLogo from '../../components/setup/BigLogo';
import SetTitle from '../../components/communal/SetTitle';

import { handleChange } from '../../utilities/generic/Forms.js'
import { cleanupVideoStream } from '../../utilities/communal/Forms.js'

class CameraSetup extends Component {

  constructor(props) {
    super(props);

    this.handleChange = handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCameraPermission = this.handleCameraPermission.bind(this);
  }

  state = {
    validDetails: false,
    cameraFeedback: "",
    cameraAccessDenied: false,

    loaded: false,
    unauthorized: false
  };

  handleSubmit(event) {
    this.setState({
      validDetails: true
    })
  }

  handleCameraPermission() {
    if (navigator.mediaDevices !== undefined) {
      if (typeof navigator.mediaDevices.getUserMedia === "function") {
        navigator.mediaDevices.getUserMedia({ video:true })
        .then( stream => {
          cleanupVideoStream(stream)
          this.setState({
            validDetails: true
          })
        })
        .catch( error => {
          if (error.name === "NotAllowedError") {
            // need to try to figure out how to make device let us ask for permission a second time? or else ditch the retry button,
            // maybe get them to restart the device setup process to dump cookies, permission memory etc?
            // A good case for putting camera setup RIGHT after initial login before location or device setup
            this.setState({
              cameraFeedback: "Please select \"Allow\" in order to use the camera.",
              cameraAccessDenied: true
            })
          }
          else {
            // should we let devices in this instance continue anyway? Can we make it so individual devices have a "hasCamera" attribute
            // and we only let Covid Pass Required profiles sign on on that individual device if `hasCamera == true`
            this.setState({
              cameraFeedback: "Your device does not appear to have access to a camera.",
              cameraAccessDenied: true
            })
          }
        })
      }
      else {
        this.setState({
          cameraFeedback: "It appears your browser is outdated. Please update to use the Camera.",
          cameraAccessDenied: true
        })
      }
    }
    else {
      this.setState({
        cameraFeedback: "There was an issue obtaining permissions.",
        cameraAccessDenied: true
      })
    }
  }

  render() {
    const { loaded, validDetails, cameraFeedback, unauthorized } = this.state;

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    if (validDetails) {
      return <Redirect to="/" />
    }

    if (unauthorized) {
      return <Redirect to="/"/>
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="center-container">
          <SetTitle title={"Setup Camera | Device Setup"} />

          <div className="login-box">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            <div className="login-form">
              <div className="center">
                <h3>Setup Camera</h3>
                <p>
                  Please grant permission to use the Camera for Covid Pass QR scanning.
                </p>
                <p>
                  {cameraFeedback}
                </p>
              </div>

              <form onSubmit={this.handleSubmit}>
                <br />
                <input type="submit" value="Skip Camera setup for now." disabled={this.state.cameraAccessDenied === false} />
              </form>
            </div>

          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    this.setState({ loaded: true });
    this.handleCameraPermission()
  }
}

export default CameraSetup;
