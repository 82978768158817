import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Honeybadger from '@honeybadger-io/js';

import HeaderText from '../../components/personal/HeaderText';
import SetTitle from '../../components/personal/SetTitle';
import { validateColorString } from '../../utilities/generic/Common'

import ArrivingOrLeavingComponent from '../../components/personal/ArrivingOrLeaving';
import ProfilesComponent from '../../components/personal/Profiles';

class ArrivingOrLeaving extends Component {

  state = {
    profile: null,
    loaded: false,
    error: null
  };

  render() {
    let token = decodeURIComponent(this.props.match.params.token)

    const { flow_order, location, profiles, loaded, error, errors, redirect } = this.state;

    // No `?page=home` in the URL. Must have scanned the Profile QR code.
    if (redirect) {
      return <Redirect to={{ pathname: "/personal/arriving-or-leaving", state: { token: token } }} />
    }

    if (errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && location && profiles) {
      return (
        <div className="main-page">
          <SetTitle title={"Arriving or Leaving"} name={location.name} />
          <HeaderText message={location.welcome_message} />

          <div className="content">
            <div className="background">
              <div className="message">
                {location.device_help_message}
              </div>
            </div>
          </div>

          {flow_order && flow_order === "arriving_or_leaving" ? (
            <ArrivingOrLeavingComponent token={token} profiles={profiles} location={location} />
          ) : (
            <ProfilesComponent token={token} profiles={profiles} location={location} />
          )}
          <div></div>
        </div>
      );
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.history.location.search)
    const page = params.get('page')

    if (page !== "home") {
      this.setState({redirect: "true"})
      return
    }

    let token = decodeURIComponent(this.props.match.params.token)

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "personal": {
        "token": token
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/location_pull`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          errorsInResponse = true
          return response.json();
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (errorsInResponse) {
          this.setState({ errors: data })
        } else {
          localStorage.setItem("background_image", JSON.stringify(data.background_image))
          localStorage.setItem("logo", JSON.stringify(data.logo))

          Honeybadger.setContext({
            locationName: data.location_name
          })

          this.setState({
            flow_order: data.flow_order,
            location: data.location,
            profiles: data.profiles,
            loaded: true
          })

          if (validateColorString(data.border_color)){
            document.querySelector(':root').style.setProperty('--page-border-color', data.border_color)
          }
          else {
            document.querySelector(':root').style.setProperty('--page-border-color', null)
          }

          if (localStorage.getItem("first_time") === null) {
            localStorage.setItem("first_time", true)
            window.location.reload()
          }
        }
      })
      .catch(error => this.setState({ error: error, loaded: true }))
  }
}

export default ArrivingOrLeaving;
