import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GenerateQRCode from '../home/GenerateQRCode';

import IntersectionComponent from '../../utilities/generic/IntersectionComponent'

class ProfilesComponent extends Component {
  constructor(props) {
    super(props)

    this.handleOutOfViewport = this.handleOutOfViewport.bind(this)

    this.elementRefs = []
  }

  state = {
    displayScrollMessage: false
  }

  handleOutOfViewport(trueOrFalse) {
    this.setState({
      displayScrollMessage: trueOrFalse
    })
  }

  gridColumns(count) {
    var output = ""

    for (var i = 0; i < count; i++) {
      output += "auto "
    }

    return output
  }

  renderScrollMessage() {
    if (this.state.displayScrollMessage === true) {
      return (
        <div className="content hide-on-mobile">
          <div className="background">
            <div className="message">
              <div className="scroll-message">Scroll for More Profiles <FontAwesomeIcon icon={faLongArrowAltRight} /></div>
            </div>
          </div>
        </div>
      )
    }
  }

  visibleQrCode(profile) {
    return (!profile.arriving_photo_required || profile.arriving_photo_required === "never") && (!profile.leaving_photo_required || profile.leaving_photo_required === "never")
  }

  render() {
    let profiles = JSON.parse(localStorage.getItem("profiles"))

    this.elementRefs = Array.from({ length: profiles.length }, () => React.createRef());

    let pathName
    let className
    let qrCodesDisplayed

    const qr_code_size = localStorage.getItem("qr_code_size") === "small" ? " small" : ""
    const direction = this.props.direction

    if (direction === null || direction === undefined) {
      // Direction hasn't been selected, so the Device has been configured to show Profiles option first
      pathName = "/arriving-or-leaving"
      className = "profile-with-qr"
      qrCodesDisplayed = profiles.filter(profile => this.visibleQrCode(profile)).length > 0
    }
    else {
      pathName = direction
      className = "profile-without-qr"
    }

    return (
      <>
        <div className="widgets hide-on-mobile" style={{gridTemplateColumns: this.gridColumns(profiles.length)}}>
          {profiles.map((profile, index) => (
            <div className={className} key={profile.id} ref={this.elementRefs[index]}>
              <Link to={{ pathname: pathName, state: { profile: profile } }} className={`widget${qr_code_size}`}>
                {profile.name}
              </Link>
              {qrCodesDisplayed &&
                <GenerateQRCode profile={profile} />
              }
            </div>
          ))}
        </div>

        <div className="widgets vertical show-on-mobile" style={{gridTemplateColumns: this.gridColumns(profiles.length)}}>
          {profiles.map((profile, index) => (
            <Link to={{ pathname: pathName, state: { profile: profile } }} className={`widget${qr_code_size}`} key={profile.id}>
              {profile.name}
            </Link>
          ))}
        </div>

        {this.renderScrollMessage()}

        <IntersectionComponent elements={this.elementRefs} onOutOfViewport={this.handleOutOfViewport} />
      </>
    )
  }
}

export default ProfilesComponent;
