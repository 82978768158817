import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../../shims/big-int'
import QrReader from 'react-qr-reader';

import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';

import HeaderText from '../../components/personal/HeaderText';
import SetTitle from '../../components/personal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';

import { formatDate } from '../../utilities/generic/Common.js';
import { handleCovidPassExpiryDate } from '../../utilities/generic/CovidPass.js';

library.add(fas, far)

class CovidQR extends Component {

  constructor(props) {
    super(props);

    this.handleDialog = this.handleDialog.bind(this);
    this.handleFlip = this.handleFlip.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleErrorSkip = this.handleErrorSkip.bind(this);
  }

  state = {
    answers: {},
    result: "Please Scan your Covid vaccination QR code",
    facing: "environment",
    legacyMode: true,

    validDetails: false,
    errors: "",
    error: ""
  }


  handleCameraFeedback() {
    if (this.state.facing === "user") {
      return "Switch to rear facing camera"
    }
    else {
      return "Switch to front facing camera"
    }
  }

  handleFlip() {
    if (this.state.facing === "user") {
      this.setState({
        facing: "environment"
      })
    }
    else {
      this.setState({
        facing: "user"
      })
    }
  }

  renderFlip() {
    if (this.state.legacyMode === false) {
      return (
        <div className="form">
          <input type="submit" value={this.handleCameraFeedback()} onClick={this.handleFlip} />
        </div>
      )
    }
  }

  handleDialog() {
    if (this.state.legacyMode === false) {
      this.setState({ legacyMode: true })
    }
    else {
      this.setState({ legacyMode: false })
    }
  }

  renderUploadButton(legacyMode) {
    if (this.state.legacyMode) {
      return (
        <div className="form">
          <input type="submit" value="Upload a QR image" onClick={() => this.refs.reader.openImageDialog()} />
        </div>
      )
    }
  }

  renderDialogFeedback() {
    if (this.state.legacyMode) {
      return "Scan your QR code"
    }
    else {
      return "Upload a QR image"
    }
  }

  handleScan(data) {
    try {
      if (data) {
        try {
          var expiryDate = handleCovidPassExpiryDate(data)
        }
        catch(err) {
          this.setState ({
            result: "Something went wrong. Please scan again or try another QR code if possible"
          })
        }

        if (expiryDate instanceof Date) {
          try {
            let currentDate = new Date().setHours(0,0,0,0)
            if (expiryDate > currentDate) {
              var headers = new Headers();
              headers.append("Content-Type", "application/json");

              let json = JSON.stringify({
                "personal": {
                  "token": this.props.location.state.token,
                  "arriving_app_id": this.props.location.state.arriving_app_id,
                  "covid_pass_expiry_date": formatDate(expiryDate),
                  "version": process.env.REACT_APP_COMMIT_HASH,
                  "created_at": formatDate(new Date()),
                  "profile_id": this.props.location.state.profile.id,
                  "type_of_person": this.props.location.state.profile.type_of_person
                }
              })

              var requestOptions = {
                method: 'POST',
                headers: headers,
                body: json,
                redirect: 'follow'
              };

              var errorsInResponse = false

              fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/covid_qr`, requestOptions)
              .then(response => {
                if (response.ok) {
                  return response.json();
                }
                else if (response.status === 401 || response.status === 422) {
                  errorsInResponse = true
                  return response.json()
                }
                else {
                  throw new Error('Something went wrong ...');
                }
              })
              .then(data => {
                if (errorsInResponse) {
                  this.setState({ errors: data })
                } else {
                  this.setState({
                    result: "COVID-19 Vaccination Pass Check Complete!",
                    validDetails: true,
                    errors: ""
                  })
                }
              })
              .catch(error => this.setState({ error }))
              // do we also want to tell visitors when their pass will expire, either if it's close to expiring or every time?
            }

            else {
              this.setState ({
                result: "Please renew your COVID-19 Vaccination Pass before continuing!"
              })
            }
          }
          catch(err) {
          }
        }
        else {
            this.setState ({
              result: "Please Scan a valid Covid Vaccination Pass"
            })
        }
      }
    }
    catch(err) {
      this.setState ({
        result: "Please Scan your Covid vaccination QR code"
      })
    }
  }

  handleError = err => {
    this.setState ({
      result: "There was an error scanning your Covid vaccination QR code. Please try again"
    })
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    let json = JSON.stringify({
      "personal": {
        "token": this.props.location.state.token,
        "arriving_app_id": this.props.location.state.arriving_app_id,
        "covid_pass_expiry_date": null,
        "version": process.env.REACT_APP_COMMIT_HASH,
        "created_at": formatDate(new Date()),
        "profile_id": this.props.location.state.profile.id,
        "type_of_person": this.props.location.state.profile.type_of_person
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/covid_qr`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 401 || response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({
          result: "COVID-19 Vaccination Pass Check Skipped.",
          validDetails: true,
          errors: ""
        })
      }
    })
    .catch(error => this.setState({ error }))
  }

  handleErrorSkip() {
    this.setState({
      validDetails: true
    })
  }

  render() {
    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    let token = this.props.location.state.token
    let profile = this.props.location.state.profile
    let person = this.props.location.state.person
    let arriving_app_id = this.props.location.state.arriving_app_id
    let direction = this.props.location.state.direction
    let planned_event_ids = this.props.location.state.planned_event_ids

    const { facing, legacyMode, validDetails, errors, error } = this.state;

    if (validDetails) {
      return <DestinationSwitch current_page="covid-qr" profile={profile} person={person} arriving_app_id={arriving_app_id} direction={direction} token={token} personal={true} planned_event_ids={planned_event_ids} />
    }

    if (errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
          <div className="form">
            <input type="submit" onClick={this.handleErrorSkip} value="Skip for now." />
          </div>
        </div>

      )
    }

    if (error) {
      return (
        <div>
          {error.message}
          <div className="form">
            <input type="submit" onClick={this.handleErrorSkip} value="Skip for now." />
          </div>
        </div>
      );
    }


    let style = { width: '44vh', margin: 'auto'}
    if (legacyMode) {
      style = {display: "none"}
    }

    return (
      <div className="main-page covid-qr-page">

        <div className="camera-container">
          <div className="background">
            {this.state.result}
          </div>
          <br/>
          {this.renderFlip()}
          {this.renderUploadButton()}
          <div className="form">
            <input type="submit" value={this.renderDialogFeedback()} onClick={this.handleDialog} />
          </div>
          <QrReader
            facingMode={facing}
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={style}
            legacyMode={legacyMode}
            onImageScan={this.handleScan}
            ref="reader"
          />
          <div className="form">
            <input type="submit" onClick={this.handleSubmit} value="Skip if unable to provide." />
          </div>
        </div>

        <SetTitle title={"Covid QR Scan"} profile={profile} />
        <HeaderText message={profile.welcome_message} />
      </div>
    );
  }
}

export default CovidQR;
