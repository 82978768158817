import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import InteractionTimeout from '../components/communal/InteractionTimeout';
import { validateLocalStorage } from '../utilities/generic/Common'

class LocalStorage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localStorageSupported: true,
      errorReason: ''
    };
  }

  getErrorReason(error) {
    const storageLimit = "Your browser has reached its storage limit. Please clear some space in your browser settings."
    const securitySettings = "Your browser security settings are preventing access to local storage. Please ensure your browser settings allow storage access."
    const unknownError = "An unknown error occurred. Please try refreshing the page or restarting your browser."

    if (error instanceof DOMException) {
      switch (error.code) {
        case 22:
          return storageLimit
        case 1014:
          // Firefox specific error code
          return storageLimit
        case 18:
          return securitySettings
        default:
          return unknownError
      }
    } else if (error.name === 'SecurityError') {
      return securitySettings
    } else {
      return unknownError
    }
  }

  localStorageWorking() {
    return (
      <div>
        <InteractionTimeout seconds={10} />

        <h2>LocalStorage is Supported</h2>
        <div>All is good! You can proceed to the <Link to={'/'}>homepage</Link>.</div>
      </div>
    )
  }

  localStorageNotWorking() {
    return (
      <div>
        <h2>LocalStorage Error</h2>
        <p>Oops! It seems there was a problem accessing your local storage.</p>
        <p>{this.state.errorReason}</p>
        <p>Please try the following steps:</p>
        <ul>
          <li>Ensure your browser settings allow storage access.</li>
          <li>Clear some space in your browser settings if the storage limit has been reached.</li>
          <li>Refresh the page or restart your browser.</li>
        </ul>
      </div>
    );
  }

  render() {
    const { localStorageSupported } = this.state;

    return (
      <div>
        <div className="error-page">
          {localStorageSupported ? (
            this.localStorageWorking()
          ) : (
            this.localStorageNotWorking()
          )}
        </div>
      </div>
    )
  }

  componentDidMount() {
    document.title = "Checking Local Storage"

    const result = validateLocalStorage()

    if (result.result === false) {
      this.setState({
        localStorageSupported: false,
        errorReason: this.getErrorReason(result.error)
      });
    }
  }
}

export default LocalStorage;
