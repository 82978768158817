// Polyfill for iOS 12 and below:
//
//   https://caniuse.com/intersectionobserver
//
import 'intersection-observer';

import { Component } from 'react';

class IntersectionComponent extends Component {
  constructor(props) {
    super(props);

    this.elementsRefs = props.elements;
    this.observer = null;

    this.handleIntersection = this.handleIntersection.bind(this);
    this.observeIntersection = this.observeIntersection.bind(this);
  }

  handleIntersection(entries) {
    // Check if any part of the elements are outside the viewport
    const isOutOfViewport = entries.some(entry => entry.intersectionRatio < 1);

    this.props.onOutOfViewport(isOutOfViewport);
  }

  observeIntersection() {
    this.elementsRefs.forEach(ref => {
      if (ref.current) {
        this.observer.observe(ref.current);
      }
    });
  }

  render() {
    return null;
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.handleIntersection);
    this.observeIntersection();
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

export default IntersectionComponent;
