import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer'

class InteractionTimeout extends Component {
  constructor(props) {
    super(props)

    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }
  
  state = {
    idle: false
  }

  handleOnIdle (event) {
    this.setState({idle: true})
  }

  render() {
    if (this.state.idle) {
      return <Redirect to="/refresh"/>
    }

    let seconds = this.props.seconds || 60

    if (seconds > 900) {
      throw new RangeError("You can't refresh the Homepage less than every 15 minutes. Doing so will break the DevicePersonal flow.")
    }

    if (process.env.REACT_APP_DISABLE_TIMEOUT === "true") {
      // Set this flag to `true` in your .env file if you'd like to disable timeouts.
      // Useful when developing on one of the later pages in the flow.
      return null
    }
    else {
      return (
        <div className="hidden">
          <IdleTimer timeout={1000 * seconds} onIdle={this.handleOnIdle} ref={ref => { this.idleTimer = ref }}  />
        </div>
      )
    }
  }
}

export default InteractionTimeout;
