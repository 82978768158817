import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import BigLogo from '../../components/setup/BigLogo';
import SetTitle from '../../components/communal/SetTitle';

import { handleChange } from '../../utilities/generic/Forms.js'

class LocationSetup extends Component {

  constructor(props) {
    super(props);

    this.handleChange = handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    locationSelected: false,
    location_id: '',

    locations: [],

    loaded: false,
    unauthorized: false
  };

  handleSubmit(event) {
    if (this.state.location_id !== '') {
      this.setState({ locationSelected: true })
    }

    event.preventDefault();
  }

  render() {
    const { location_id, locations, loaded, locationSelected, unauthorized } = this.state;

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    if (locationSelected) {
      return <Redirect to={{ pathname: "/setup/device", state: { locationId: location_id, emailAddress: this.props.location.state.emailAddress, password: this.props.location.state.password, provider: this.props.location.state.provider, credential: this.props.location.state.credential } }} />
    }

    if (unauthorized) {
      return <Redirect to="/"/>
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="center-container">
          <SetTitle title={"Select Location | Device Setup"} />

          <div className="login-box">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            <div className="login-form">
              <div className="center">
                <h3>Select Location</h3>
                <p>
                  Select the location your device is part of.
                </p>
              </div>

              <form onSubmit={this.handleSubmit}>
                <label>
                  <select name="location_id" onChange={this.handleChange} value={location_id}>
                    <option value="select">Select a location</option>
                    {locations.map((location) => (
                      <option value={location.id} key={location.id}>{location.name}</option>
                    ))}
                  </select>
                </label>
                <br />
                <input type="submit" value="Continue" />
              </form>
            </div>

          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      return
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "setup": {
        "email_address": this.props.location.state.emailAddress,
        "password": this.props.location.state.password,
        "provider": this.props.location.state.provider,
        "credential": this.props.location.state.credential
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/setup/location`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 400) {
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default LocationSetup;
