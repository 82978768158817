import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import BigLogo from '../../components/setup/BigLogo';
import SetTitle from '../../components/communal/SetTitle';
import InteractionTimeout from '../../components/communal/InteractionTimeout';

import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import MicrosoftLoginButton from './MicrosoftLoginButton';

import { handleChange } from '../../utilities/generic/Forms.js'

class UserSetup extends Component {
  constructor(props) {
    super(props);

    this.handleChange = handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleMicrosoftLogin = this.handleMicrosoftLogin.bind(this);
    this.handleMicrosoftLoginError = this.handleMicrosoftLoginError.bind(this);
  }

  state = {
    emailAddress: "",
    password: "",

    validDetails: false,
    errors: "",
    error: "",
    googleError: "",
    microsoftError: ""
  };

  validateDetails(provider = null, credential = null) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "setup": {
        "email_address": this.state.emailAddress,
        "password": this.state.password,
        "provider": provider,
        "credential": credential
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/setup/user`, requestOptions)
    .then(res => res.json())
      .then((data) => {
        if (data === true) {
          this.setState({validDetails: true})
        }
        else if (data.errors) {
          this.setState({errors: JSON.stringify(data)})
        }
      })
      .catch(error => this.setState({error: error.message}))
  }

  handleSubmit(event) {
    this.validateDetails()

    event.preventDefault();
  }

  handleGoogleLogin(response) {
    this.setState({provider: "google", credential: response.credential})

    this.validateDetails("google", response.credential)
  }

  handleMicrosoftLogin(idToken) {
    this.setState({provider: "microsoft", credential: idToken})

    this.validateDetails("microsoft", idToken)
  }

  renderErrors(state) {
    if (state.errors.length > 0) {
      var json = JSON.parse(state.errors)
      var messages = json.errors

      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {messages.map((message) => (
              <li>{message}</li>
            ))}
          </ul>
        </div>
      )
    }

    if (state.error.length > 0) {
      return (
        <div>{state.error}</div>
      )
    }
  }

  handleMicrosoftLoginError(message) {
    this.setState({"error": message})
  }

  render() {
    const { emailAddress, password, provider, credential } = this.state;

    if (this.state.validDetails) {
      return <Redirect to={{ pathname: "/setup/location", state: { emailAddress: emailAddress, password: password, provider: provider, credential: credential } }} />
    }

    const msConfig = { auth: { clientId: "e364d967-a8a3-46cf-8541-26e4de77f4f1" } }
    const mspca = new PublicClientApplication(msConfig);

    return (
      <div className="center-container">
        <InteractionTimeout seconds={300} />

        <SetTitle title={"Device Setup"} />

        <div className="login-box">
          <p className="welcome-to">Welcome to...</p>

          <BigLogo />

          <div className="login-form">
            <h3 className="center">Device Setup</h3>
            <p>
              To setup this device, go to the Digital Reception administration app and add a new device.
            </p>
            <p>
              Then enter the username and password below. You'll be asked to select the location and then select the device.
            </p>

            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <label>
                <input placeholder=" email or username" aria-required="true" type="email" name="emailAddress" value={emailAddress} autoComplete="email" onChange={this.handleChange} />
              </label>
              <br />
              <label>
                <input placeholder=" password" type="password" name="password" value={password} autoComplete="current-password" onChange={this.handleChange} />
              </label>
              <br />
              <input type="submit" value="Submit" />
            </form>

            { this.renderErrors(this.state) }

            <div className="third-party-login-container">
              <GoogleOAuthProvider clientId="987710099044-h78amjc7vdebv45125mg8i6cd209aqa4.apps.googleusercontent.com">
                <GoogleLogin onSuccess={this.handleGoogleLogin} onError={(error) => { this.setState({"error": error}) }} />
              </GoogleOAuthProvider>
            </div>

            <MsalProvider instance={mspca}>
              <div className="third-party-login-container">
                <MicrosoftLoginButton handleMicrosoftLogin={(idToken) => this.handleMicrosoftLogin(idToken)} handleMicrosoftLoginError={(error) => this.handleMicrosoftLoginError(error)} />
              </div>
            </MsalProvider>
          </div>

        </div>
      </div>
    );
  }
}

export default UserSetup;
