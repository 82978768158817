import React, { Component } from 'react';

class Logo extends Component {
  render() {

    let logo = null

    if (localStorage !== null) {
      logo = localStorage.getItem("logo")
    }

    if (logo !== null && logo !== "null") {
      // `logo` comes through as `""http://api.dreception.com/image.jpg""` so we need to trim off the extra quotes
      logo = logo.slice(1, -1)
      return (
        <div className={`logo-container ${this.props.size}`}>
          <img src={logo} className="logo" alt="Logo" />
        </div>
      )
    }
    else {
      return (<div/>)
    }
  }
}

export default Logo;
