import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import SimpleReactValidator from 'simple-react-validator';

import HeaderText from '../../components/communal/HeaderText';
import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';

import { formatDate, generateId, debounce, scrollElementToTop } from '../../utilities/generic/Common.js'
import { renderRequired, requiredString } from '../../utilities/generic/Forms.js'

class CustomAttributes extends Component {

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.debouncedScrollElementToTop = debounce(scrollElementToTop, 1000);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  state = {
    custom_attributes: this.customAttributesInitialiser(),
    showConfirmationMessage: this.containsExistingData(),

    validDetails: false,
    errors: "",
    error: "",

    direction: "arriving"
  }

  customAttributesInitialiser() {
    if (this.props.location.state) {
      let custom_attributes = {}

      // this creates empty values for new custom attributes
      this.props.location.state.profile.custom_attributes.forEach((custom_attribute) => {
        custom_attributes[custom_attribute.id] = ""
      })

      // this fills in already created custom attributes
      let person_custom_attributes = this.props.location.state.person.custom_attributes
      person_custom_attributes.forEach((person_custom_attribute) => {
        custom_attributes[person_custom_attribute.id] = person_custom_attribute.value
      })

      return (custom_attributes)
    }
    else {
      return {}
    }
  }

  containsExistingData() {
    // Protect against people visiting /custom-attributes page directly
    if (this.props.location.state === undefined) {
      return false
    }

    // return true if any custom_attribute_id from existing person data matches any custom_attribute_id from the profile
    // so long as it's not only acknowledgements
    if (this.props.location.state.person.custom_attributes.length > 0) {
      return this.props.location.state.person.custom_attributes.some(person_custom_attribute =>
        this.props.location.state.profile.custom_attributes.some(profile_custom_attribute =>
          person_custom_attribute.id === profile_custom_attribute.id && profile_custom_attribute.answer_type !== "acknowledgement"
        )
      )
    }
    else return false
  }

  handleChange(event) {
    let custom_attribute_id = parseInt(event.target.name);
    let value = event.target.value;

    let custom_attributes = this.state.custom_attributes;

    custom_attributes[custom_attribute_id] = value

    this.setState({custom_attributes: custom_attributes});

    if (event.target.type === "text") {
      this.debouncedScrollElementToTop(event.target)
    }
    else {
      scrollElementToTop(event.target)
    }
  }

  handleCheckbox(event) {
    let custom_attribute_id = parseInt(event.target.name);
    let value = event.target.value;
    let checked = event.target.checked;

    let custom_attributes = this.state.custom_attributes;

    custom_attributes[custom_attribute_id] = checked ? value : ""

    this.setState({custom_attributes: custom_attributes});

    scrollElementToTop(event.target)
  }

  handleSubmit(event) {
    if (this.validator.allValid()) {
      let customAttribute = {
        "custom_attributes": this.state.custom_attributes,
        "arriving_app_id": this.props.location.state.arriving_app_id,
        "app_id": generateId(),
        "version": process.env.REACT_APP_COMMIT_HASH,
        "created_at": formatDate(new Date())
      }

      let customAttributes = JSON.parse(localStorage.getItem("custom_attributes"))

      // Create custom_attributes array if it doesn't exist
      if (customAttributes === null) {
        customAttributes = []
      }

      // Append to custom_attributes array, and save to semi-permanent storage
      customAttributes.push(customAttribute)
      localStorage.setItem("custom_attributes", JSON.stringify(customAttributes))

      this.setState({validDetails: true})
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }

    event.preventDefault()
  }

  handleClose() {
    this.setState({ showConfirmationMessage: false })
  }

  alreadyAcknowledged(profile_custom_attribute_id) {
    const person_custom_attributes = this.props.location.state.person.custom_attributes
    const previousAcknoledgement = person_custom_attributes.find((custom_attribute) => custom_attribute.id === profile_custom_attribute_id && custom_attribute.value === "Acknowledged")
    return previousAcknoledgement !== undefined
  }

  renderConfirmation() {
    if (this.state.showConfirmationMessage) {
      return (
      <div className="autocomplete-container">
        <div className="autocomplete-content">
          Please confirm this information is correct:
          <button className="autocomplete-button" value="ok" onClick={this.handleClose}>OK</button>
        </div>
      </div>
      )
    }
  }

  renderProfileCustomAttributes(profile_custom_attribute) {
    let person_custom_attributes = this.state.custom_attributes

    if (profile_custom_attribute.answer_type === "boolean") {
      return (
        <div className="feedback-page" key={profile_custom_attribute.id}>
          <div className="question radio">
            <div>{renderRequired(profile_custom_attribute.required)}{profile_custom_attribute.name}</div>
            <input className="column" type="checkbox" name={profile_custom_attribute.id} value="Yes" id={`yes_${profile_custom_attribute.id}`} checked={person_custom_attributes[profile_custom_attribute.id] === "Yes"} onChange={this.handleChange} />
            <label className="longer" htmlFor={`yes_${profile_custom_attribute.id}`}>Yes</label>
            <input className="column" type="checkbox" name={profile_custom_attribute.id} value="No" id={`no_${profile_custom_attribute.id}`} checked={person_custom_attributes[profile_custom_attribute.id] === "No"} onChange={this.handleChange} />
            <label className="longer" htmlFor={`no_${profile_custom_attribute.id}`}>No</label>
            {this.validator.message('above', person_custom_attributes[profile_custom_attribute.id], requiredString(profile_custom_attribute.required, "string"), { className: 'error top-margin' })}
          </div>
        </div>
      )
    }

    if (profile_custom_attribute.answer_type === "acknowledgement") {
      // If an acknowledgement has previously been acknowledged, we do not show them the acknowledment again
      if (this.alreadyAcknowledged(profile_custom_attribute.id)){
        return
      }
      else {
        return (
          <div key={profile_custom_attribute.id}>
            <div className="message" htmlFor="checkbox">{renderRequired(profile_custom_attribute.required)}{profile_custom_attribute.name}</div>
            <input className="message" type="checkbox" name={profile_custom_attribute.id} value="Acknowledged" id={profile_custom_attribute.id} checked={person_custom_attributes[profile_custom_attribute.id] === "Acknowledged"} onChange={this.handleCheckbox} />
            {this.validator.message('above', person_custom_attributes[profile_custom_attribute.id], requiredString(profile_custom_attribute.required, "string"), { className: 'error' })}
          </div>
        )
      }
    }

    else {
      return (
        <label key={profile_custom_attribute.id} className="bottom-margin">
          <div className="text">{renderRequired(profile_custom_attribute.required)}{profile_custom_attribute.name}:</div>
          <input aria-required="true" type="text" name={profile_custom_attribute.id} value={person_custom_attributes[profile_custom_attribute.id]} autoComplete="off" onChange={this.handleChange} />
          <div className="blank"></div>
          {this.validator.message('above', person_custom_attributes[profile_custom_attribute.id], requiredString(profile_custom_attribute.required, "string"), { className: 'error' })}
        </label>
      )
    }
  }

  renderRequiredContext() {
    // take a note of pre-existing Person Custom Attributes with a value of "Acknowledged"
    const acknowledgedCustomAttributeIDs = this.props.location.state.person.custom_attributes.filter(
      (person_custom_attribute) => person_custom_attribute.value === "Acknowledged"
    ).map(
      (person_custom_attribute) => person_custom_attribute.id
    );

    // filter out their corresponding Profile Custom Attributes from this check if they are also currently set as the "acknowledgement" answer_type
    const visibleCustomAttributes = this.props.location.state.profile.custom_attributes.filter(
      (profile_custom_attribute) => profile_custom_attribute.answer_type !== "acknowledgement" || !acknowledgedCustomAttributeIDs.includes(profile_custom_attribute.id)
      );

    // check if any remaining Profile Custom Attributes (i.e. those that will render) are required
    const containsRequired = visibleCustomAttributes.some((profile_custom_attribute) => profile_custom_attribute.required === true);

    if (containsRequired){
      return <div className="red">* Required fields.</div>
    }
  }

  render() {
    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    if (this.state.validDetails) {
      let profile = this.props.location.state.profile
      let person = this.props.location.state.person
      let arriving_app_id = this.props.location.state.arriving_app_id
      let direction = this.props.location.state.direction
      let planned_event_ids = this.props.location.state.planned_event_ids

      return <DestinationSwitch current_page="custom-attributes" profile={profile} person={person} arriving_app_id={arriving_app_id} direction={direction} planned_event_ids={planned_event_ids} />
    }

    let profile_custom_attributes = this.props.location.state.profile.custom_attributes

    return (
      <div className="main-page arriving-page">
        <InteractionTimeout seconds={60} />

        <SetTitle title={"Custom Attributes"} />
        <HeaderText />

        <div className="content">
          <div className="widget form">
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <div className="message">
                Please fill in the following details if you are able:
              </div>

              {profile_custom_attributes.map((profile_custom_attribute) => {
                return this.renderProfileCustomAttributes(profile_custom_attribute)
              })}
              <br />
              {this.renderRequiredContext()}
              <input type="submit" value="Submit" />
            </form>

            {this.renderConfirmation()}
          </div>
        </div>

        <div></div>
      </div>
    );
  }
}

export default CustomAttributes;
