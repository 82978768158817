import React, { Component } from 'react';

class SetTitle extends Component {
  state = {
    currentTitle: "",
    previousTitle: ""
  }

  componentDidMount() {
    let profile = this.props.profile
    let name = this.props.name

    var locationName = ""
    if (profile !== undefined && profile !== null && profile.location_name !== null) {
      locationName = `| ${profile.location_name}`
    }

    if (name) {
      locationName = `| ${name}`
    }

    let title = this.props.title;

    let currentTitle = `${title} ${locationName} | Digital Reception`

    this.setState({"previousTitle": document.title})
    this.setState({"currentTitle": currentTitle})

    document.title = currentTitle
  }

  componentWillUnmount() {
    document.title = this.state.previousTitle
  }

  render() {
    return (
      <>
      </>
    );
  }
};

export default SetTitle;
